import React from 'react';
const profilePic = require('../../../assets/images/profile_pic.png');

const CustomButton = ({ label, color, textColor = 'text-white' }) => {
	return <button className={`flex h-11 w-40 ${color} rounded border justify-center items-center my-2 `}>
		<span className={`font-bold text-sm ${textColor}`}>{label}</span>
	</button>
}
const LabelContainer = ({ label, containerStyle }) => {
    return <div className={`flex rounded items-center justify-center bg-[#F5F5F5] border border-gray-300 h-10 w-64 ${containerStyle}`}>
        <span className='text-m text-sm font-bold text-gray-700'>{label}</span>
    </div>
}

const Details = ({
	userDetails
}) => {
	return <div className='flex justify-center items-center w-full'>
		<div className='h-full w-full border mx-10 my-3 px-10'>
			<div className='flex items-center justify-around flex-1 my-10'>
				{/* Avatar Container */}
				<div className='flex flex-col items-center'>
					<div className='border p-2'>
						<div className='bg-[#f7f7f7] h-fit rounded bg-[#FAFAFA] p-3'>
							<img src={userDetails?.profileImage ?? profilePic} alt="me" width="215" height="215" className="rounded-full" />
						</div>
					</div>
					<span className='text-[#00b300] mt-4'>Active</span>
				</div>
				{/* Card */}
				<div className='bg-[#FAFAFA] rounded flex-1 p-4 border border-[#f5f5f5] mx-5'>
					<div className='flex justify-between items-center py-2 border-t border-[#f5f5f5]'>
						<span className='text-left text-[#808080] text-sm'>Account Number:</span>
						<LabelContainer
							label='9797995'
						/>
					</div>
					<div className='flex justify-between items-center py-2 border-t border-[#f5f5f5]'>
						<span className='text-left text-[#808080] text-sm'>User Name:</span>
						<LabelContainer
							label={userDetails?.name}
						/>
					</div>
					<div className='flex justify-between items-center py-2 border-t border-[#f5f5f5]'>
						<span className='text-left text-[#808080] text-sm'>User email:</span>
						<LabelContainer
							label={userDetails?.email}
						/>
					</div>
					<div className='flex justify-between items-center py-2 border-y border-[#f5f5f5]'>
						<span className='text-left text-[#808080] text-sm'>Register Date / Location:</span>
						<LabelContainer
							label={userDetails?.registerDateLocation}
						/>
					</div>
					<div className='flex justify-between items-center py-2 border-b border-[#f5f5f5]'>
						<span className='text-left text-[#808080] text-sm'>Last Login / Location:</span>
						<LabelContainer
							label={userDetails?.lastLoginDateLocation}
						/>
					</div>
				</div>
				{/* Action button container */}
				<div className='flex flex-col bg-[#FAFAFA] rounded py-5 border border-[#f5f5f5] '>
					<div className='pb-5 px-5'>
						<CustomButton
							label='Reset password'
							color={'bg-[#03A9F5]'}
						/>
						<CustomButton
							label='Suspend Account'
							color={'bg-[#FDEE32]'}
							textColor={'text-[#434445]'}
						/>
					</div>
					<div className='border-t border-dashed pt-5 px-5'>
						<CustomButton
							label='Delete Account'
							color={'bg-black'}
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
}

export default Details