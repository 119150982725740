import { useEffect, useState } from "react";

const ClockOutlineIcon = ({ className, fill }) => {
    const [_class, setClass] = useState(className)

    useEffect(() => {
        let classStr = className || '';
        if (!classStr.includes('w-')) {
            classStr += ' w-5';
        }
        if (!classStr.includes('h-')) {
            classStr += ' h-5';
        }

        setClass(classStr);
    }, [className])

    return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="0" stroke={fill} class={_class}>
      <path d="M12.3809 4C10.2591 4 8.2243 4.84285 6.72401 6.34315C5.22371 7.84344 4.38086 9.87827 4.38086 12C4.38086 14.1217 5.22371 16.1566 6.72401 17.6569C8.2243 19.1571 10.2591 20 12.3809 20C14.5026 20 16.5374 19.1571 18.0377 17.6569C19.538 16.1566 20.3809 14.1217 20.3809 12C20.3809 9.87827 19.538 7.84344 18.0377 6.34315C16.5374 4.84285 14.5026 4 12.3809 4ZM2.38086 12C2.38086 6.477 6.85786 2 12.3809 2C17.9039 2 22.3809 6.477 22.3809 12C22.3809 17.523 17.9039 22 12.3809 22C6.85786 22 2.38086 17.523 2.38086 12ZM12.3809 6C12.6461 6 12.9004 6.10536 13.088 6.29289C13.2755 6.48043 13.3809 6.73478 13.3809 7V11.586L16.0879 14.293C16.27 14.4816 16.3708 14.7342 16.3685 14.9964C16.3663 15.2586 16.2611 15.5094 16.0757 15.6948C15.8903 15.8802 15.6395 15.9854 15.3773 15.9877C15.1151 15.99 14.8625 15.8892 14.6739 15.707L11.6739 12.707C11.4863 12.5195 11.3809 12.2652 11.3809 12V7C11.3809 6.73478 11.4862 6.48043 11.6738 6.29289C11.8613 6.10536 12.1156 6 12.3809 6Z" fill={fill}/>
    </svg>
};

export default ClockOutlineIcon;

