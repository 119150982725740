const ContentWrapper = ({ children, className }) => {
	return (
		<div className={`w-full px-8 md:py-8 min-h-screen  bg-gray-50 ${className ?? ''}`}>
			<div className="bvCardContainer mx-auto">
				{children}
			</div>
		</div>
	)
}

export default ContentWrapper;