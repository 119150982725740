const BVCard = ({ children, className, style }) => {
	return (
		<div
			className={`w-full bg-white border shadow-sm ${className ?? ''}`}
			style={style}
		>
			{children}
		</div>
	)
}

export default BVCard;