import React, { useEffect, useState } from "react";

const PencilIcon = ({
    size = "24",
    fill = 'none',
    className
}) => {
    const [_class, setClass] = useState(className)

    useEffect(() => {
        let classStr = className || '';
        if (!classStr.includes('w-')) {
            classStr += ' w-5';
        }
        if (!classStr.includes('h-')) {
            classStr += ' h-5';
        }

        setClass(classStr);
    }, [className])
    return <svg viewBox={`0 0 24 24`} fill={fill} xmlns="http://www.w3.org/2000/svg" className={_class}>
        <path d="M19.9141 6.92735L16.0736 3.08681C15.9619 2.97507 15.8292 2.88644 15.6833 2.82597C15.5373 2.7655 15.3808 2.73437 15.2228 2.73438C15.0648 2.73437 14.9083 2.7655 14.7624 2.82597C14.6164 2.88644 14.4838 2.97507 14.372 3.08681L3.77422 13.6838C3.66227 13.7953 3.57351 13.9279 3.51304 14.0739C3.45257 14.22 3.42158 14.3765 3.42188 14.5345V18.3751C3.42188 18.6942 3.54863 19.0002 3.77426 19.2258C3.99989 19.4515 4.30591 19.5782 4.625 19.5782H19.0625C19.1993 19.5782 19.3304 19.5239 19.4271 19.4272C19.5238 19.3305 19.5781 19.1993 19.5781 19.0626C19.5781 18.9258 19.5238 18.7947 19.4271 18.698C19.3304 18.6013 19.1993 18.547 19.0625 18.547H9.99524L19.9141 8.62891C20.0259 8.51719 20.1145 8.38456 20.175 8.23858C20.2355 8.0926 20.2666 7.93614 20.2666 7.77813C20.2666 7.62013 20.2355 7.46367 20.175 7.31769C20.1145 7.17171 20.0259 7.03907 19.9141 6.92735ZM7.07336 16.6563L14.5938 9.13509L16.2713 10.8126L8.75 18.333L7.07336 16.6563ZM6.34375 15.9276L4.66711 14.2501L12.1875 6.7297L13.865 8.40634L6.34375 15.9276ZM4.45313 18.3751V15.4945L7.50563 18.547H4.625C4.57942 18.547 4.5357 18.5289 4.50347 18.4966C4.47124 18.4644 4.45313 18.4207 4.45313 18.3751ZM19.1845 7.89931L17 10.0838L12.9171 6.00009L15.1008 3.81642C15.1167 3.80043 15.1357 3.78776 15.1566 3.77911C15.1774 3.77046 15.1998 3.76601 15.2224 3.76601C15.245 3.76601 15.2673 3.77046 15.2882 3.77911C15.3091 3.78776 15.328 3.80043 15.344 3.81642L19.1845 7.6561C19.2005 7.67207 19.2132 7.69102 19.2218 7.71189C19.2305 7.73275 19.2349 7.75512 19.2349 7.7777C19.2349 7.80029 19.2305 7.82266 19.2218 7.84352C19.2132 7.86439 19.2005 7.88334 19.1845 7.89931Z" fill={fill} />
    </svg>

}
export default PencilIcon
