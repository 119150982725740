import api from "../api";
import axios from "axios";
import handleError from "./handle-error";
import { getAxiosConfig } from "./axios.config";

export const getUsers = async (accType, cursor) => {
    try {
        if (accType == undefined)
            throw new Error("Please choose user accountType filter param.");

        let urlParams = new URLSearchParams();
        urlParams.append('accType', accType);
        if (cursor) {
            urlParams.append('cursor', cursor);
        }
        const query = urlParams.toString();
        const { data } = await axios.get(api.getUsers + `/?${query}`, getAxiosConfig());

        if (!data.success) {
            throw new Error("Failed to fetch users.");
        }

        return data;
    } catch (err) {
        return handleError(err);
    }
}

export const getUserById = async (id) => {
    try {
        const { data } = await axios.get(api.getUserById+`/${id}`, getAxiosConfig());
        if (!data.success)
            throw new Error("Failed to fetch user details.");

        return data.user;
    } catch (err) {
        return handleError(err);
    }
}