import { useSnackbar } from "notistack";
import React from "react";
import { createProvisionLab, stopEC2, terminateEC2 } from "../../endpoints/aws.services";
import BVCard from "../../global/components/BVCard";

const data = [
    {
        user: 'Student 1',
        labs: [
            {
                name: 'Lab 1',
                instances: 2,
                status: 'Provisioned'
            },
            // {
            //     name: 'Lab 2',
            //     instances: 3,
            //     status: 'Not Provisioned'
            // },
            // {
            //     name: 'Lab 3',
            //     instances: 4,
            //     status: 'Terminated'
            // }
        ]
    },
    // {
    //     user: 'Student 2',
    //     labs: [
    //         {
    //             name: 'Lab 1',
    //             instances: 2,
    //             status: 'Provisioned'
    //         },
    //         {
    //             name: 'Lab 2',
    //             instances: 3,
    //             status: 'Not Provisioned'
    //         },
    //         {
    //             name: 'Lab 3',
    //             instances: 4,
    //             status: 'Terminated'
    //         }
    //     ]
    // }
]

const AwsLabPage = () => {
    const { enqueueSnackbar } = useSnackbar()

    const handleCreateProvision = () => {
        enqueueSnackbar('checking', { variant: 'success' });
        createProvisionLab().then((res) => {
            console.log('====================================');
            console.log('provision created', res);
            console.log('====================================');
            alert('SUCCESS'+`\n${res?.msg}`)
        })
    }

    const handleTerminateLab = () => {
        terminateEC2().then((res) => {
            console.log('====================================');
            console.log('provision terminated', res);
            console.log('====================================');
            alert('SUCCESS'+`\n${res?.message}`)
        })
    }

    const handleStopLab = () => {
        stopEC2().then((res) => {
            console.log('====================================');
            console.log('provision stopped', res);
            console.log('====================================');
            alert('SUCCESS'+`\n Stopping...`)
        })
    }

    return <BVCard>
        <div className="border-b h-10 w-full" />
        <div className="p-10">
            <div className="flex flex-col gap-2 p-10 border rounded-md">
                {
                    data?.map((item) => {
                        return <div className="bg-gray-100 rounded-sm w-full p-3">
                            <div className="flex">
                                <div className="w-[10%]">
                                    <span className="text-xs text-gray-600 font-bold">{item?.user}</span>
                                </div>
                                <div className="w-[25%] text-center">

                                </div>
                                <div className="w-[45%]">

                                </div>
                                <div className="w-[20%] text-center">
                                    <span className="text-xs text-gray-600 font-bold">Status</span>
                                </div>
                            </div>
                            <div className="flex flex-col gap-3 mt-3">
                                {
                                    item?.labs?.map((lab) => {
                                        return <div className="flex w-full">
                                            <div className="w-[10%]">
                                                <span className="text-xs text-gray-600 font-bold">{lab?.name}</span>
                                            </div>
                                            <div className="w-[25%] text-center">
                                                <span className="text-xs text-gray-600 font-bold">{lab?.instances} x Linux instances</span>
                                            </div>
                                            <div className="flex w-[45%] sm:gap-3 xl:gap-10 2xl:gap-20">
                                                <button
                                                    onClick={handleCreateProvision}
                                                    className="bg-green-600 text-xss px-3 py-1.5 font-semibold text-white rounded-sm">
                                                    Provision Lab
                                                </button>
                                                <button
                                                    onClick={handleStopLab}
                                                    className="bg-[#FDEE32] border text-xss px-3 py-1.5 font-semibold text-white rounded-sm">
                                                    Suspend Lab
                                                </button>
                                                <button
                                                    onClick={handleTerminateLab}
                                                    className="bg-black text-xss px-3 py-1.5 font-semibold text-white rounded-sm">
                                                    Terminate Lab
                                                </button>
                                            </div>
                                            <div className="w-[20%] text-center">
                                                <span className="text-xs text-gray-600">{lab?.status}</span>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    </BVCard>
}

export default AwsLabPage