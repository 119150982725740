import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatHhMmSs } from '../../../common/timeFunc';
import { getCoursesList, getTrainerCourseList } from '../../../endpoints/courses.service';
import BVCard from '../../../global/components/BVCard';

const Courses = ({ userDetails }) => {
    const navigate = useNavigate();
    const [courses, setCourses] = useState({
        data: [],
        cursor: { next: null }
    });
    const [loading, setLoading] = useState(false)

    const fetchCourses = async () => {
        setLoading(true);
        let params = {};
        if (courses?.cursor?.next) {
            params['cursor'] = courses.cursor.next;
        }
        getTrainerCourseList(userDetails.id, params).then((_courses) => {
            setCourses({
                ...courses,
                data: [
                    ...(courses.cursor?.next ? courses.data : []),
                    ...(_courses.data ?? [])
                ],
                cursor: _courses.cursor
            });
        }).finally(() => setLoading(false))
    }

    const onClickLoadMore = (e) => {
        e.preventDefault();
        fetchCourses();
    }

    useEffect(() => {
        fetchCourses()
    }, [])

    function handleClick(id) {
        window.open(`/courses/detail/${id}`);
    }

    return (
        <div className='flex w-full my-3 px-10'>
            <div className=' w-full border'>
                <div className='flex py-10 px-5'>
                    <input className='border mr-5' />
                    <input className='border mr-5' />
                    <input className='border mr-5' />
                </div>
                <table className="w-full">
                    <thead>
                        <tr className='bg-gray-100'>
                            <th className='text-gray-400 text-xs font-normal h-10'>Course ID</th>
                            <th className='text-gray-400 text-xs font-normal h-10'>Title</th>
                            <th className='text-gray-400 text-xs font-normal h-10'>Username</th>
                            <th className='text-gray-400 text-xs font-normal h-10'>Duration</th>
                            <th className='text-gray-400 text-xs font-normal h-10'>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            courses?.data?.map((item, index) => {
                                const isPublished = item?.publish && (item?.review == "approved")
                                return <tr key={item?.id} className='hover:cursor-pointer'>
                                    <td onClick={() => handleClick(item?.id)} className={`border-l-0 text-black text-xs text-center border h-10 ${index === (courses?.length - 1) ? 'border-b-0' : 'border-b'}`}>{item?.id}</td>
                                    <td onClick={() => handleClick(item?.id)} className={`text-black text-xs text-center border h-10 ${index === (courses?.length - 1) ? 'border-b-0' : 'border-b'}`}>{item?.topicTitle}</td>
                                    <td onClick={() => handleClick(item?.id)} className={`text-black text-xs text-center border h-10 ${index === (courses?.length - 1) ? 'border-b-0' : 'border-b'}`}>{item?.user?.name}</td>
                                    <td onClick={() => handleClick(item?.id)} className={`text-black text-xs text-center border h-10 ${index === (courses?.length - 1) ? 'border-b-0' : 'border-b'}`}>{item?.courseDuration ? formatHhMmSs(item?.courseDuration) : '00:00:00'}</td>
                                    <td onClick={() => handleClick(item?.id)} className={`border-r-0 text-xs text-center font-semibold border h-10 ${index === (courses?.length - 1) ? 'border-b-0' : 'border-b'} ${isPublished ? 'text-success' : 'text-danger'}`}>{isPublished ? 'PUBLISHED' : 'Approval(PENDING)'}</td>
                                </tr>
                            })
                        }

                        {
                            loading ? (
                                [1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => {
                                    return <tr key={item} className='hover:cursor-pointer'>
                                        <td className={`border-l-0 text-black text-xs text-center border h-10 ${index == 7 ? 'border-b-0' : 'border-b'}`}>
                                            <div className="w-full h-full flex justify-center items-center">
                                                <div className="z-0 w-[80%] h-3 bg-gray-300 rounded animate-pulse"></div>
                                            </div>
                                        </td>
                                        <td className={`border-l-0 text-black text-xs text-center border h-10 ${index == 7 ? 'border-b-0' : 'border-b'}`}>
                                            <div className="w-full h-full flex justify-center items-center">
                                                <div className="z-0 w-[80%] h-3 bg-gray-300 rounded animate-pulse"></div>
                                            </div>
                                        </td>
                                        <td className={`border-l-0 text-black text-xs text-center border h-10 ${index == 7 ? 'border-b-0' : 'border-b'}`}>
                                            <div className="w-full h-full flex justify-center items-center">
                                                <div className="z-0 w-[80%] h-3 bg-gray-300 rounded animate-pulse"></div>
                                            </div>
                                        </td>
                                        <td className={`border-l-0 text-black text-xs text-center border h-10 ${index == 7 ? 'border-b-0' : 'border-b'}`}>
                                            <div className="w-full h-full flex justify-center items-center">
                                                <div className="z-0 w-[80%] h-3 bg-gray-300 rounded animate-pulse"></div>
                                            </div>
                                        </td>
                                        <td className={`border-r-0 text-black text-xs text-center border h-10 ${index == 7 ? 'border-b-0' : 'border-b'}`}>
                                            <div className="w-full h-full flex justify-center items-center">
                                                <div className="z-0 w-[80%] h-3 bg-gray-300 rounded animate-pulse"></div>
                                            </div>
                                        </td>
                                    </tr>
                                })
                            ) : null
                        }
                    </tbody>
                </table>
                {
                    courses.cursor.next && !loading ? (
                        <div className="text-center">
                            <a href="#" className="text-medium" onClick={onClickLoadMore}>Load More</a>
                        </div>
                    ) : null
                }
            </div>
        </div>
    )
}
export default Courses;
