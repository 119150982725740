import * as moment from 'moment';
import React, { useState, useEffect } from 'react';
import ArrowBackIcon from '../../assets/svgIcons/ArrowBackIcon';
import CourseIdIcon from '../../assets/svgIcons/CourseIdIcon';
import CurrencyIcon from '../../assets/svgIcons/CurrencyIcon';
import DeleteIcon from '../../assets/svgIcons/DeleteIcon';
import EditIcon from '../../assets/svgIcons/EditIcon';
import FacebookIcon from '../../assets/svgIcons/FacebookIcon';
import InstaIcon from '../../assets/svgIcons/InstaIcon';
import LanguageIcon from '../../assets/svgIcons/LanguageIcon';
import LevelsIcon from '../../assets/svgIcons/LevelsIcon';
import LinkedInIcon from '../../assets/svgIcons/LinkedInIcon';
import PencilIcon from '../../assets/svgIcons/PencilIcon';
import TwitterIcon from '../../assets/svgIcons/TwitterIcon';
import BVCard from '../../global/components/BVCard';
import placeholderImg from '../../assets/images/placeholderImg.png'
import LinkIcon from '../../assets/svgIcons/LinkIcon';
import UnpublishIcon from '../../assets/svgIcons/UnpublishIcon';
import PreviewIcon from '../../assets/svgIcons/PreviewIcon';
import { useNavigate, useParams } from 'react-router-dom';
import { getCourseById, publishCourse } from '../../endpoints/courses.service';
import success from '../../assets/icons/success.png'
import draft from '../../assets/icons/draft.png'
import StarIcon from '../../assets/svgIcons/StarIcon';
import AddUserIcon from '../../assets/svgIcons/AddUserIcon';
import WalletIcon from '../../assets/svgIcons/WalletIcon';
import CommentIcon from '../../assets/svgIcons/CommentIcon';
import PublishIcon from '../../assets/svgIcons/PublishIcon';
import { formatHhMmSs } from '../../common/timeFunc';


const tabs = [
    'Course Details',
    'Attendes',
    'Payments',
    'Comments'
]
const languages = [
    'English',
]

const ItemDetail = ({ item, index }) => {
    return <div className="flex items-center">
        {item?.icon}
        <span className="flex items-center text-ngray ml-2">{item?.label ? item?.label + ' : ' : ''} {item?.value}</span>
    </div>
}

const CustomButton = ({
    buttonStyle,
    textStyle,
    icon,
    label
}) => {
    return <div className={`cursor-pointer rounded flex items-center justify-center w-max border h-8 px-3 ${buttonStyle ?? ''}`}>
        {icon}
        <span className={`ml-2 border-ngray ${textStyle ?? ''}`}>{label}</span>
    </div>
}

const CourseDetailPage = () => {
    const navigate = useNavigate();
    const { topicId } = useParams()
    const [topic, setTopic] = useState();
    const [isPublished, setIsPublished] = useState(false);
    const [deletingAction, setDeletingAction] = useState(false);
    const [publishingAction, setPublishingAction] = useState(false);


    useEffect(() => {
        setIsPublished(topic?.publish && topic.review == "approved");
    }, [topic])


    const handleBack = () => {
        navigate(-1);
    }

    const togglePublish = () => {
        if (!window.confirm(`Do you really want to ${topic.publish ? 'unpublish' : 'publish'} this course?`)) {
            return;
        }
        let token = localStorage.getItem('token')
        setPublishingAction(true);
        publishCourse(token, topic?.id)
            .then((data) => {
                console.log(data);
                if (data.success) {
                    setTopic({
                        ...topic,
                        publish: !topic.publish,
                        review: 'approved'
                    });
                }
            })
            .finally(() => {
                setPublishingAction(false);
            });
    };

    async function fetchCourseDetails(topicId) {
        let token = localStorage.getItem('token')
        await getCourseById(token, topicId).then((res) => {
            console.log(res);
            setTopic(res?.topic)
        })
    }

    useEffect(() => {
        console.log('====================================');
        console.log('check');
        console.log('====================================');
        if (topicId) {
            fetchCourseDetails(topicId)
        }
    }, [topicId])

    const R1Col1 = [
        {
            icon: <CourseIdIcon className={'h-7 w-7'} fill="#d3d3d3" />,
            label: 'Course ID',
            value: topic?.id
        },
        {
            icon: <LevelsIcon fill="#d3d3d3" />,
            label: 'Levels',
            value: <div className="bg-successLite rounded-full px-3 ml-1.5">
                <span className="text-success text-xs font-semibold">Beginners</span>
            </div>
        },
        // {
        //   icon: <ClockIcon fill="#d3d3d3" />,
        //   label: 'Duration',
        //   value: '2hr 50mins'
        // },
        {
            icon: <LanguageIcon fill="#d3d3d3" />,
            label: 'Languages',
            value: <div className="flex">
                {
                    languages?.map((item, index) => {
                        return <div className="bg-blueLite rounded-full px-3 mx-1.5">
                            <span className="text-nblue text-xs font-semibold">{item}</span>
                        </div>
                    })
                }
                <div className="bg-blueLite rounded-full px-3">
                    <span className="text-nblue text-xs font-semibold">+2</span>
                </div>
            </div>
        },
        {
            // icon: <ClockIcon fill="#d3d3d3" />,
            // label: 'Duration',
            // value: '2hr 50mins'
        },
    ]

    const R1Col2 = [
        {
            icon: <PencilIcon className={'h-7 w-7'} fill="#d3d3d3" />,
            label: 'Edited',
            value: moment(topic?.updatedAt).format('DD MMM YYYY')
        },
        {
            icon: <PencilIcon className={'h-7 w-7'} fill="#d3d3d3" />,
            label: 'Created',
            value: moment(topic?.createdAt).format('DD MMM YYYY')
        },
        {
            icon: <PencilIcon className={'h-7 w-7'} fill="#d3d3d3" />,
            label: 'Published',
            value: topic?.publishedDate ? moment(topic?.publishedDate).format('DD MMM YYYY') : 'null'
        },
        {
            icon: <CurrencyIcon fill="#d3d3d3" />,
            label: 'Course Fee',
            value: topic?.topicPrice ? '£ ' + topic?.topicPrice?.topicPrice : 'null'
        }
    ]

    const R2Col1 = [
        {
            icon: <AddUserIcon className={'h-7 w-7'} fill="#d3d3d3" />,
            label: 'Registrations',
            value: 123445
        },
        {
            icon: <StarIcon className={'h-7 w-7'} fill="#d3d3d3" />,
            label: 'Ratings',
            value: <div className="flex items-center bg-blueLite rounded-full px-1.5 py-0.5 ml-1.5">
                <StarIcon className={'h-7 w-7'} fill={"#3984C6"} />
                <StarIcon className={'h-7 w-7'} fill={"#3984C6"} />
                <StarIcon className={'h-7 w-7'} fill={"#3984C6"} />
                <StarIcon className={'h-7 w-7'} fill={"#3984C6"} />
                <StarIcon className={'h-7 w-7'} fill="#D3d3d3" />
            </div>
        },
    ]

    const R2Col2 = [
        {
            icon: <WalletIcon fill="#d3d3d3" />,
            label: 'Revenue',
            value: '150,000'
        },
        {
            icon: <CommentIcon fill="#d3d3d3" />,
            label: 'Comments',
            value: '150'
        },
    ]
    return <BVCard>
        <div className='p-10'>
            <div
                onClick={handleBack}
                className='flex w-full cursor-pointer'>
                <ArrowBackIcon size="36" fill='#3984c6' />
            </div>
            {/* Body container */}
            <div style={{
                height: '500px'
            }} className="flex flex-col w-full bg-white border-2 h-max rounded px-10 py-4 mt-4">
                {/* Desc container  */}
                <div className="flex w-full h-full">
                    {/* Col 1 */}
                    <div className="w-1/4 h-full">
                        {/* Image container  */}
                        <div style={{ height: '48%' }} className="w-full pb-3 relative">
                            <img src={topic?.courseGridPhoto ?? placeholderImg} className='rounded h-full w-full' />
                            <div className={`flex items-center justify-center ${isPublished ? 'bg-successLite' : 'bg-dangerLite'} rounded-full w-max px-3 py-1 absolute top-0 ml-3 mt-3`}>
                                <img src={isPublished ? success : draft} width={27} height={27} />
                                <span className={`${isPublished ? 'text-success' : 'text-danger'} text-base font-semibold ml-1`}>{isPublished ? 'Published' : 'Approval(Pending)'}</span>
                            </div>
                            <span className="bg-black rounded text-white absolute right-px bottom-4 px-2.5 py-px text-sm">{topic?.courseDuration ? formatHhMmSs(topic?.courseDuration) : '00:00:00'}</span>
                        </div>
                        {/* Social container */}
                        <div style={{ height: '52%' }} className="flex flex-col justify-around items-center border bg-bvblue2 rounded">
                            <span className="text-white font-semibold text-xl">Share your course</span>
                            <div className="bg-white rounded flex items-center justify-center py-1.5 px-3">
                                <LinkIcon size="21" fill="#3984C6" />
                                <span className="ml-1 text-bvblue2">Copy Link</span>
                            </div>
                            <div className="flex flex-col items-center w-full">
                                <span className="text-white text-sm font-medium mb-2">Share social</span>
                                <div className="flex justify-evenly w-full">
                                    <TwitterIcon size='39' fill="white" />
                                    <InstaIcon size='39' fill="white" />
                                    <FacebookIcon size='39' fill="white" />
                                    <LinkedInIcon size='39' fill="white" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Col 2 */}
                    <div className="rounded flex flex-col w-3/4 ml-4 h-full">
                        {/* Title container */}
                        <div className="flex items-center justify-center rounded py-1 bg-blueLite mb-3">
                            <span className="text-bvblue2 font-semibold text-lg">{topic?.topicTitle ?? 'No Title'}</span>
                        </div>
                        <div className="w-full h-full">
                            {/* Row 1 */}
                            <div className="h-3/5">
                                <div className="flex bg-nwhited border rounded h-full">
                                    {/* Col 1 */}
                                    <div className="flex flex-col pl-20 justify-around py-3 w-2/4">
                                        {
                                            R1Col1?.map((item, index) => {
                                                return <ItemDetail item={item} />
                                            })
                                        }
                                    </div>
                                    {/* Col 2 */}
                                    <div className="flex flex-col pl-20 justify-around py-3 w-2/4">
                                        {
                                            R1Col2?.map((item, index) => {
                                                return <ItemDetail item={item} />
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* Row 2 */}
                            <div className="w-full pt-3 h-2/5">
                                <div className="flex bg-nwhited border rounded h-full">
                                    {/* Col 1 */}
                                    <div className="flex flex-col pl-20 justify-around py-3 w-2/4">
                                        {
                                            R2Col1?.map((item, index) => {
                                                return <ItemDetail item={item} />
                                            })
                                        }
                                    </div>
                                    {/* Col 2 */}
                                    <div className="flex flex-col pl-20 justify-around py-3 w-2/4">
                                        {
                                            R2Col2?.map((item, index) => {
                                                return <ItemDetail item={item} />
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Action container */}
                <div className="flex items-center justify-end w-full mt-3 pl-4">
                    <div className="flex items-center justify-between w-3/4">
                        <div className="flex items-center">
                            {
                                isPublished ?
                                    <div
                                    // onClick={() => !publishingAction ? togglePublish() : ''}
                                    >
                                        <CustomButton
                                            icon={<UnpublishIcon fill={'#3984C6'} size={'15'} />}
                                            label='Unpublish'
                                            textStyle={'text-bvblue2'}
                                            buttonStyle={'mr-3 bg-white'}
                                        />
                                    </div>
                                    :
                                    <div
                                        onClick={() => !publishingAction ? togglePublish() : ''}
                                    >
                                        <CustomButton
                                            icon={<PublishIcon fill={'#3984C6'} className={'h-4 w-4'} />}
                                            label='Publish'
                                            textStyle={'text-bvblue2'}
                                            buttonStyle={'mr-3 bg-white'}
                                        />
                                    </div>
                            }
                            {/* <CustomButton
                                icon={<PreviewIcon fill={'white'} size={'18'} />}
                                buttonStyle={'mr-3 bg-success'}
                                label='Preview'
                                textStyle={'text-white'}
                            /> */}
                            {/* <Link
                role="button"
                style={{ textDecoration: 'none' }}
                to={
                  (topic?.courseType == 2
                    ? `/teacher/my-courses/create-course/${LINKS[topic?.completeStep]
                    }/`
                    : "/teacher/my-courses/create-course/content/") +
                  topic?.id
                }
              >
                <CustomButton
                  icon={<EditIcon fill={'black'} size={'18'} />}
                  textStyle={'text-black hover:no-underline'}
                  buttonStyle={'bg-myellow'}
                  label='Edit'
                />
              </Link> */}

                        </div>
                        <div className="flex items-center">
                            {/* <div
                            // onClick={() => !deletingAction ? onClickDelete() : ''}
                            >
                                <CustomButton
                                    icon={<DeleteIcon fill={'#F55A5A'} size={'18'} />}
                                    label='Delete'
                                    buttonStyle={'bg-white'}
                                    textStyle={'text-danger'}
                                />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </BVCard>
}

export default CourseDetailPage