import api from "../api";
import axios from "axios";
import handleError from "./handle-error";
import { getAxiosConfig } from "./axios.config";

export const getTrainerCourseList = async (trainerUserId, params) => {
    try {
        const urlParams = new URLSearchParams(params);
        const { data } = await axios.get(api.trainerCourses(trainerUserId) + `&${urlParams.toString()}`, getAxiosConfig());
        if (!data.success)
            throw new Error("Failed to fetch trainer courses.");
        
        return data.courses;
    } catch (err) {
        return handleError(err);
    }
}

export const getCoursesList = async () => {
    try {
        const { data } = await axios.get(api.getCoursesList, getAxiosConfig());

        return data;
    } catch (err) {
        return handleError(err);
    }
}

export const getCourseById = async (token, id) => {
    try {
        const { data } = await axios.get(api.getCourseById + id, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });

        return data;
    } catch (err) {
        return handleError(err);
    }
}

export const publishCourse = async (token,id) => {
    try {
        const { data } = await axios.post(api.publishCourse + id + '/publish',{}, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });

        return data;
    } catch (err) {
        return handleError(err);
    }
};

//   export const deleteTopic = async (topicId) => {
//     try {
//       const { data } = await axios.delete(api.topicById(topicId));
//       if (data.success) {
//         return true;
//       } else {
//         throw new Error("Failed to delete course.");
//       }
//     } catch (err) {
//       return handleError(err);
//     }
//   }
