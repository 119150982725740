import React from "react";

const InstaIcon = ({
    size = "24",
    fill = 'none'
}) => {
    return <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path d="M12.675 3.25H26.325C31.525 3.25 35.75 7.475 35.75 12.675V26.325C35.75 28.8247 34.757 31.222 32.9895 32.9895C31.222 34.757 28.8247 35.75 26.325 35.75H12.675C7.475 35.75 3.25 31.525 3.25 26.325V12.675C3.25 10.1753 4.24299 7.77805 6.01052 6.01052C7.77805 4.24299 10.1753 3.25 12.675 3.25ZM12.35 6.5C10.7985 6.5 9.31051 7.11634 8.21343 8.21343C7.11634 9.31051 6.5 10.7985 6.5 12.35V26.65C6.5 29.8838 9.11625 32.5 12.35 32.5H26.65C28.2015 32.5 29.6895 31.8837 30.7866 30.7866C31.8837 29.6895 32.5 28.2015 32.5 26.65V12.35C32.5 9.11625 29.8838 6.5 26.65 6.5H12.35ZM28.0313 8.9375C28.57 8.9375 29.0866 9.15151 29.4676 9.53244C29.8485 9.91337 30.0625 10.43 30.0625 10.9688C30.0625 11.5075 29.8485 12.0241 29.4676 12.4051C29.0866 12.786 28.57 13 28.0313 13C27.4925 13 26.9759 12.786 26.5949 12.4051C26.214 12.0241 26 11.5075 26 10.9688C26 10.43 26.214 9.91337 26.5949 9.53244C26.9759 9.15151 27.4925 8.9375 28.0313 8.9375ZM19.5 11.375C21.6549 11.375 23.7215 12.231 25.2452 13.7548C26.769 15.2785 27.625 17.3451 27.625 19.5C27.625 21.6549 26.769 23.7215 25.2452 25.2452C23.7215 26.769 21.6549 27.625 19.5 27.625C17.3451 27.625 15.2785 26.769 13.7548 25.2452C12.231 23.7215 11.375 21.6549 11.375 19.5C11.375 17.3451 12.231 15.2785 13.7548 13.7548C15.2785 12.231 17.3451 11.375 19.5 11.375ZM19.5 14.625C18.2071 14.625 16.9671 15.1386 16.0529 16.0529C15.1386 16.9671 14.625 18.2071 14.625 19.5C14.625 20.7929 15.1386 22.0329 16.0529 22.9472C16.9671 23.8614 18.2071 24.375 19.5 24.375C20.7929 24.375 22.0329 23.8614 22.9472 22.9472C23.8614 22.0329 24.375 20.7929 24.375 19.5C24.375 18.2071 23.8614 16.9671 22.9472 16.0529C22.0329 15.1386 20.7929 14.625 19.5 14.625Z" fill={fill} />
    </svg>

}
export default InstaIcon

