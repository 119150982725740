import React, { useEffect, useState } from "react";
import {
	Navigate,
	Outlet,
	Route,
	Routes,
} from "react-router-dom";
import ContentWrapper from "../global/components/ContentWrapper";
import Header from "../global/components/Header";
import Sidebar from "../global/components/Sidebar";
import Page404 from "../global/pages/Page404";
import AwsLabPage from "../pages/AwsLab/AwsLabPage";
import CourseDetailPage from "../pages/CourseDetail/CourseDetailPage";
import CoursesPage from "../pages/Courses/CoursesPage";
import HomePage from "../pages/Home/HomePage";
import Login from "../pages/Login/LoginPage";
import UserDetails from "../pages/UserDetails";
import UserPage from "../pages/Users/UsersPage";
import Feeds from "../pages/Feeds";


const PublicLayout = ({ children }) => {
	const [navHeight, setNavHeight] = useState(0);


	useEffect(() => {
		setTimeout(() => {
			const topNav = document.getElementById('top-navbar');
			if (topNav) {
				let height = topNav.offsetHeight;
				setNavHeight(height);
			}
		}, 100);
	}, [])

	return <div className="p-0 bg-[#f5f5f5]" style={{ "--nav-height": `${navHeight}px` }}>
		<Header />
		<div className="flex" id="main-container"
			style={{ marginTop: "var(--nav-height)" }}
		>
			<div className="w-56 flex-none">
				<Sidebar />
			</div>
			<div className="grow w-full" style={{ minHeight: "95vh" }}>
				<ContentWrapper>
					{children}
				</ContentWrapper>
			</div>
		</div>
	</div>
}


const AuthUserRoute = ({ children }) => {
	const token = localStorage.getItem('token')
	return <PublicLayout>
		{
			token ?
				<Outlet />
				:
				<Navigate to={'/login'} />
		}
	</PublicLayout>
}


const AppRouter = () => {
	const token = localStorage.getItem('token')
	// if (!token) {
	// 	navigate('/login')
	// }

	return <Routes>
		<Route path="/login" element={<Login />} />
		<Route path="/" element={<AuthUserRoute />}>
			<Route index path="dashboard" element={<HomePage />} />
			{/* Users */}
			<Route path="users">
				<Route index element={<UserPage />} />
				<Route path=":id/*" element={<UserDetails />} />
			</Route>
			{/* Courses */}
			<Route path="courses">
				<Route index element={<CoursesPage />} />
				<Route path="detail/:topicId" element={<CourseDetailPage />} />
			</Route>
			<Route path="aws-lab" element={<AwsLabPage />} />
			<Route path="feed" element={<Feeds />} />
			<Route path="*" element={<Page404 />} />
		</Route>
	</Routes>
}

export default AppRouter