import logo from './logo.svg';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate, BrowserRouter } from "react-router-dom";
import './App.css';
import HomePage from './pages/Home/HomePage';
import AppRouter from './router';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
          <AppRouter />
      </BrowserRouter>
    </div>
  );
}

export default App;
