import React from "react";

const TwitterIcon = ({
    size = "24",
    fill = 'none'
}) => {
    return <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path d="M36.4969 9.75C35.2457 10.3188 33.8969 10.6925 32.4994 10.8713C33.9294 10.01 35.0344 8.645 35.5544 7.00375C34.2057 7.81625 32.7107 8.385 31.1344 8.71C29.8507 7.3125 28.0469 6.5 25.9994 6.5C22.1807 6.5 19.0607 9.62 19.0607 13.4713C19.0607 14.0238 19.1257 14.56 19.2394 15.0638C13.4545 14.7713 8.3032 11.9925 4.87445 7.78375C4.2732 8.8075 3.93195 10.01 3.93195 11.2775C3.93195 13.6988 5.1507 15.8438 7.0357 17.0625C5.88195 17.0625 4.80945 16.7375 3.86695 16.25V16.2988C3.86695 19.6788 6.27195 22.5063 9.45695 23.14C8.43438 23.4198 7.36086 23.4588 6.3207 23.2538C6.76206 24.639 7.62645 25.8512 8.79235 26.7198C9.95826 27.5884 11.3671 28.0698 12.8207 28.0963C10.3566 30.047 7.3022 31.1014 4.15945 31.0863C3.60695 31.0863 3.05445 31.0538 2.50195 30.9888C5.58945 32.9713 9.26195 34.125 13.1944 34.125C25.9994 34.125 33.0357 23.4975 33.0357 14.2838C33.0357 13.975 33.0357 13.6825 33.0194 13.3738C34.3844 12.3988 35.5544 11.1638 36.4969 9.75Z" fill={fill} />
    </svg>

}
export default TwitterIcon
