
import React from "react";

const CurrencyIcon = ({
    size = "24",
    fill = 'none'
}) => {
    return <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path d="M10.5033 18.8359C5.90075 18.8359 2.16992 15.105 2.16992 10.5025C2.16992 5.90002 5.90075 2.16919 10.5033 2.16919C15.1058 2.16919 18.8366 5.90002 18.8366 10.5025C18.8366 15.105 15.1058 18.8359 10.5033 18.8359ZM10.5033 17.1692C12.2714 17.1692 13.9671 16.4668 15.2173 15.2166C16.4675 13.9663 17.1699 12.2706 17.1699 10.5025C17.1699 8.73441 16.4675 7.03872 15.2173 5.78848C13.9671 4.53823 12.2714 3.83586 10.5033 3.83586C8.73514 3.83586 7.03945 4.53823 5.78921 5.78848C4.53897 7.03872 3.83659 8.73441 3.83659 10.5025C3.83659 12.2706 4.53897 13.9663 5.78921 15.2166C7.03945 16.4668 8.73514 17.1692 10.5033 17.1692ZM8.00325 11.3359H7.16992V9.66919H8.00325V8.83586C8.00296 8.1588 8.23822 7.50274 8.66869 6.98015C9.09917 6.45757 9.69804 6.10103 10.3626 5.97166C11.0272 5.84229 11.7161 5.94816 12.3111 6.2711C12.9062 6.59405 13.3704 7.11396 13.6241 7.74169L11.9683 8.15586C11.8222 7.92854 11.6063 7.75473 11.353 7.6605C11.0998 7.56627 10.8228 7.5567 10.5636 7.63322C10.3045 7.70975 10.0771 7.86824 9.9157 8.08493C9.75426 8.30161 9.66744 8.56481 9.66825 8.83502V9.66835H12.1683V11.335H9.66825V13.0017H13.8349V14.6684H7.16825V13.0017H8.00159V11.335L8.00325 11.3359Z" fill={fill} />
    </svg>

}
export default CurrencyIcon
