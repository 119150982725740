import React from "react";

const LevelsIcon = ({
    size = "24",
    fill = 'none'
}) => {
    return <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill={fill} xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_117_50)">
            <path d="M2.4743 14.9735H5.26097V12.1868C5.26097 11.8464 5.53805 11.5693 5.87926 11.5693H8.66593V8.78268C8.66593 8.44068 8.94301 8.16281 9.28422 8.16281H12.0709V5.37693C12.0709 5.03493 12.348 4.75785 12.69 4.75785H15.4758V1.97197C15.4758 1.62997 15.7529 1.35289 16.0949 1.35289H18.8816C19.222 1.35289 19.4999 1.62997 19.4999 1.97197V18.3785C19.4999 18.7205 19.2228 18.9976 18.8808 18.9976H2.4743C2.31011 18.9976 2.15265 18.9323 2.03655 18.8162C1.92044 18.7001 1.85522 18.5427 1.85522 18.3785V15.5918C1.85522 15.2514 2.1323 14.9735 2.4743 14.9735ZM0.636053 12.6104C0.548938 12.5232 0.5 12.4051 0.5 12.2818C0.5 12.1586 0.548938 12.0405 0.636053 11.9533L12.4556 0.133722C12.5433 0.0492449 12.6606 0.00255083 12.7824 0.0036822C12.9041 0.00481356 13.0206 0.0536799 13.1067 0.139772C13.1928 0.225864 13.2416 0.342305 13.2428 0.464052C13.2439 0.5858 13.1972 0.703128 13.1127 0.790805L1.29314 12.6104C1.11105 12.7925 0.818137 12.7925 0.636053 12.6104Z" fill={fill} />
        </g>
        <defs>
            <clipPath id="clip0_117_50">
                <rect width="19" height="19" fill="white" transform="translate(0.5)" />
            </clipPath>
        </defs>
    </svg>

}
export default LevelsIcon
