import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BVCard from '../../global/components/BVCard';
import { getCoursesList } from '../../endpoints/courses.service';
import { formatHhMmSs } from '../../common/timeFunc';

const CoursesPage = () => {
	const navigate = useNavigate();
	const [courses, setCourses] = useState([])
	const [loading, setLoading] = useState(false)

	const fetchCourses = async () => {
		setLoading(true)
		const token = localStorage.getItem('token')
		await getCoursesList(token).then((res) => {
			console.log('====================================');
			console.log(res);
			console.log('====================================');
			setCourses(res?.topics?.data)
		}).finally(() => setLoading(false))
	}

	useEffect(() => {
		fetchCourses()
	}, [])

	function handleClick(id) {
		navigate(`detail/${id}`);
	}

	return (
		<BVCard className="min-h-[30rem]">
			<div className='flex justify-center pt-2'>
				<span>Courses</span>
			</div>
			{/* Table container */}
			<div>
				<div className='flex w-full my-3 px-14'>
					<div className=' w-full border'>
						<div className='flex py-10 px-5'>
							<input className='border mr-5' />
							<input className='border mr-5' />
							<input className='border mr-5' />
						</div>
						<table className="w-full">
							<thead>
								<tr className='bg-gray-100'>
									<th className='text-gray-400 text-xs font-normal h-10'>Course ID</th>
									<th className='text-gray-400 text-xs font-normal h-10'>Title</th>
									<th className='text-gray-400 text-xs font-normal h-10'>Username</th>
									<th className='text-gray-400 text-xs font-normal h-10'>Duration</th>
									<th className='text-gray-400 text-xs font-normal h-10'>Status</th>
									<th className='text-gray-400 text-xs font-normal h-10'>Actions</th>
								</tr>
							</thead>
							<tbody>
								{
									loading ?
										<>
											{
												[1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => {
													return <tr key={item} className='hover:cursor-pointer'>
														<td className={`border-l-0 text-black text-xs text-center border h-10 ${index == 7 ? 'border-b-0' : 'border-b'}`}>
															<div className="w-full h-full flex justify-center items-center">
																<div className="z-0 w-[80%] h-3 bg-gray-300 rounded animate-pulse"></div>
															</div>
														</td>
														<td className={`border-l-0 text-black text-xs text-center border h-10 ${index == 7 ? 'border-b-0' : 'border-b'}`}>
															<div className="w-full h-full flex justify-center items-center">
																<div className="z-0 w-[80%] h-3 bg-gray-300 rounded animate-pulse"></div>
															</div>
														</td>
														<td className={`border-l-0 text-black text-xs text-center border h-10 ${index == 7 ? 'border-b-0' : 'border-b'}`}>
															<div className="w-full h-full flex justify-center items-center">
																<div className="z-0 w-[80%] h-3 bg-gray-300 rounded animate-pulse"></div>
															</div>
														</td>
														<td className={`border-l-0 text-black text-xs text-center border h-10 ${index == 7 ? 'border-b-0' : 'border-b'}`}>
															<div className="w-full h-full flex justify-center items-center">
																<div className="z-0 w-[80%] h-3 bg-gray-300 rounded animate-pulse"></div>
															</div>
														</td>
														<td className={`border-r-0 text-black text-xs text-center border h-10 ${index == 7 ? 'border-b-0' : 'border-b'}`}>
															<div className="w-full h-full flex justify-center items-center">
																<div className="z-0 w-[80%] h-3 bg-gray-300 rounded animate-pulse"></div>
															</div>
														</td>
														<td className={`border-r-0 text-black text-xs text-center border h-10 ${index == 7 ? 'border-b-0' : 'border-b'}`}>
															<div className="w-full h-full flex justify-center items-center">
																<div className="z-0 w-[80%] h-3 bg-gray-300 rounded animate-pulse"></div>
															</div>
														</td>
													</tr>
												})
											}
										</>
										:
										<>
											{
												courses?.map((item, index) => {
													const isPublished = item?.publish && (item?.review == "approved")
													return <tr key={item?.id} className='hover:cursor-pointer'>
														<td onClick={() => handleClick(item?.id)} className={`border-l-0 text-black text-xs text-center border h-10 ${index === (courses?.length - 1) ? 'border-b-0' : 'border-b'}`}>{item?.id}</td>
														<td onClick={() => handleClick(item?.id)} className={`text-black text-xs text-center border h-10 ${index === (courses?.length - 1) ? 'border-b-0' : 'border-b'}`}>{item?.topicTitle}</td>
														<td onClick={() => handleClick(item?.id)} className={`text-black text-xs text-center border h-10 ${index === (courses?.length - 1) ? 'border-b-0' : 'border-b'}`}>{item?.user?.name}</td>
														<td onClick={() => handleClick(item?.id)} className={`text-black text-xs text-center border h-10 ${index === (courses?.length - 1) ? 'border-b-0' : 'border-b'}`}>{item?.courseDuration ? formatHhMmSs(item?.courseDuration) : '00:00:00'}</td>
														<td onClick={() => handleClick(item?.id)} className={`border-r-0 text-xs text-center font-semibold border h-10 ${index === (courses?.length - 1) ? 'border-b-0' : 'border-b'} ${isPublished ? 'text-success' : 'text-danger'}`}>{isPublished ? 'PUBLISHED' : 'Approval(PENDING)'}</td>
														<td onClick={() => handleClick(item?.id)} className={`border-r-0 text-xs text-center font-semibold border h-10 ${index === (courses?.length - 1) ? 'border-b-0' : 'border-b'}`}>
															{
																isPublished ?
																	<button className='text-orange-700 hover:bg-gray-100 px-2 py-1 rounded-sm'>
																		UNPUBLISH
																	</button>
																	:
																	<button className='text-orange-700 hover:bg-gray-100 px-2 py-1 rounded-sm'>
																		DISAPPROVE
																	</button>
															}
														</td>
													</tr>
												})
											}
										</>
								}

							</tbody>
						</table>
					</div>
				</div>
			</div>
		</BVCard>
	)
}
export default CoursesPage;
