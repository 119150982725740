import React from "react";

const WalletIcon = ({
    size = "24",
    fill = 'none'
}) => {
    return <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill={'none'} xmlns="http://www.w3.org/2000/svg">
        <path d="M6.5 8.5H10.5" stroke="#D3D3D3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M21.333 9.5H18.731C16.946 9.5 15.5 10.843 15.5 12.5C15.5 14.157 16.947 15.5 18.73 15.5H21.333C21.417 15.5 21.458 15.5 21.493 15.498C22.033 15.465 22.463 15.066 22.498 14.565C22.5 14.533 22.5 14.494 22.5 14.417V10.583C22.5 10.506 22.5 10.467 22.498 10.435C22.462 9.934 22.033 9.535 21.493 9.502C21.459 9.5 21.417 9.5 21.333 9.5Z" stroke="#D3D3D3" stroke-width="1.5" />
        <path d="M21.465 9.5C21.387 7.628 21.137 6.48 20.328 5.672C19.157 4.5 17.271 4.5 13.5 4.5H10.5C6.729 4.5 4.843 4.5 3.672 5.672C2.5 6.843 2.5 8.729 2.5 12.5C2.5 16.271 2.5 18.157 3.672 19.328C4.843 20.5 6.729 20.5 10.5 20.5H13.5C17.271 20.5 19.157 20.5 20.328 19.328C21.137 18.52 21.388 17.372 21.465 15.5" stroke="#D3D3D3" stroke-width="1.5" />
        <path d="M18.4902 12.5H18.5002" stroke="#D3D3D3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

}
export default WalletIcon
