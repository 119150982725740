import React, { useState, useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

const sidebarOptions = [
    {
        route: '/dashboard',
        icon: require('../../assets/icons/dashboard.png'),
        activeIcon: require('../../assets/icons/dashboard-active.png'),
        label: 'Dashboard'
    },
    {
        route: '/users',
        icon: require('../../assets/icons/users.png'),
        activeIcon: require('../../assets/icons/users-active.png'),
        label: 'Users'
    },
    {
        route: '/courses',
        icon: require('../../assets/icons/courses.png'),
        activeIcon: require('../../assets/icons/courses-active.png'),
        label: 'Courses'
    },
    {
        route: '/feed',
        icon: require('../../assets/icons/feed.png'),
        activeIcon: require('../../assets/icons/feed-active.png'),
        label: 'Feed'
    },
    {
        route: '/challenges',
        icon: require('../../assets/icons/challenges.png'),
        activeIcon: require('../../assets/icons/challenges-active.png'),
        label: 'Challenges'
    },
    {
        route: '/aws-lab',
        icon: require('../../assets/icons/aws.png'),
        activeIcon: require('../../assets/icons/aws-active.png'),
        label: 'AWS'
    }
]


const sidebarOptions2 = [
    {
        route: '/transaction',
        icon: require('../../assets/icons/transaction.png'),
        activeIcon: require('../../assets/icons/transaction-active.png'),
        label: 'Transaction'
    },
    {
        route: '/admin-users',
        icon: require('../../assets/icons/admin-users.png'),
        activeIcon: require('../../assets/icons/admin-users-active.png'),
        label: 'Admin users'
    },
]

const ItemComponent = ({
    item,
    index,
    location
}) => {
    const isSelected = location?.pathname?.includes(item?.route)
    return <Link relative="route" to={item?.route}>
        <li className={`flex my-5 w-full py-2 pl-5 text-sm items-center rounded font-semibold ${isSelected ? 'bg-[#3984C7] text-white' : 'text-[#7F7F7F]'} ${!location?.pathname?.includes(item?.route) && 'hover:bg-blueLite'}`}>
            <img className='mr-3' src={isSelected ? item?.activeIcon : item?.icon} alt="me" width="24" height="24" />
            {item?.label}</li>
    </Link>
}

const Sidebar = () => {
    const location = useLocation();

    return (
        <div className='h-screen bg-[white] shadow-md fixed w-56'>
            <div className="w-full flex flex-col items-end">
                <ul className="w-[90%]">
                    {
                        sidebarOptions?.map((item, index) => {
                            return <ItemComponent
                                key={index}
                                item={item}
                                index={index}
                                location={location}
                            />
                        })
                    }
                </ul>
                <div className='w-full border-dashed border border-slate-200' />
                <ul className="w-[90%]">
                    {
                        sidebarOptions2?.map((item, index) => {
                            return <ItemComponent
                                key={index}
                                item={item}
                                index={index}
                                location={location}
                            />
                        })
                    }
                </ul>
            </div>
        </div>
    )
}

export default Sidebar