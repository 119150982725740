import React from "react";

const FacebookIcon = ({
    size = "24",
    fill = 'none'
}) => {
    return <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path d="M35.75 19.5C35.75 10.53 28.47 3.25 19.5 3.25C10.53 3.25 3.25 10.53 3.25 19.5C3.25 27.365 8.84 33.9137 16.25 35.425V24.375H13V19.5H16.25V15.4375C16.25 12.3012 18.8013 9.75 21.9375 9.75H26V14.625H22.75C21.8563 14.625 21.125 15.3562 21.125 16.25V19.5H26V24.375H21.125V35.6687C29.3313 34.8562 35.75 27.9337 35.75 19.5Z" fill={fill} />
    </svg>

}
export default FacebookIcon
