import moment from "moment"
// import regexList from "utils/regexList"

export const convertSecondToHrFormat = (seconds) => {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60
    return {
        hr: hours,
        min: minutes,
        sec: remainingSeconds.toFixed(0)
    }
}

export const convertSecondToTimestampFormat = (seconds) => {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60
    return hours > 0 ? hours + ':' : '' + (minutes > 0 ? minutes + ':' : '00:') + (Math.floor(remainingSeconds) < 10 ? '0' + Math.floor(remainingSeconds) : Math.floor(remainingSeconds))
}



export const convertIntoSecond = (value) => {
    const hours = value?.hr > 0 ? value?.hr * 60 * 60 : 0
    const minutes = value?.min > 0 ? value?.min * 60 : 0
    const remainingSeconds = value?.sec
    return parseFloat(hours + minutes + remainingSeconds)
}

export const timeSincePost = (postedDateTime) => {
    var second = 1000;
    var minute = 60000;
    var hour = 3600000;
    var day = 86400000;
    var week = 604800000;
    var month = 2419200000;
    var year = 29030400000;
    let timeDiff = moment().diff(moment(postedDateTime))
    let formattedOutput;

    if (timeDiff < minute) {
        formattedOutput = Math.round(timeDiff / second)
        return (formattedOutput > 5 ? formattedOutput : '') + (formattedOutput > 5 ? ' seconds ago' : ' Just now')
    } else if (timeDiff < hour) {
        formattedOutput = Math.round(timeDiff / minute)
        return formattedOutput + (formattedOutput > 1 ? ' minutes ago' : ' minute ago')
    } else if (timeDiff < day) {
        formattedOutput = Math.round(timeDiff / hour)
        return formattedOutput + (formattedOutput > 1 ? ' hours ago' : ' hour ago')
    } else if (timeDiff < week) {
        formattedOutput = Math.round(timeDiff / day)
        return formattedOutput + (formattedOutput > 1 ? ' days ago' : ' day ago')
    } else if (timeDiff < month) {
        formattedOutput = Math.round(timeDiff / week)
        return formattedOutput + (formattedOutput > 1 ? ' weeks ago' : ' week ago')
    } else if (timeDiff < year) {
        formattedOutput = Math.round(timeDiff / month)
        return formattedOutput + (formattedOutput > 1 ? ' months ago' : ' month ago')
    } else {
        formattedOutput = Math.round(timeDiff / year)
        return formattedOutput + (formattedOutput > 1 ? ' years ago' : ' month ago')
    }
}

export const timeSinceLastMessage = (createdDate) => {
    var startOfWeek = moment().startOf('week');
    var endOfWeek = moment().endOf('week');
    const isToday = moment(createdDate).isSame(moment(), 'day')
    const isYesterday = moment(createdDate).isSame(moment(createdDate).subtract(1, 'day'), 'day')
    const isCurrentWeek = moment(createdDate).isBetween(moment().subtract(7, 'day'), moment())
    if (isToday) {
        return moment(createdDate).format('HH:MM a')
    } else if (isYesterday) {
        return 'Yesterday'
    } else if (isCurrentWeek) {
        return moment(createdDate).format('ddd')
    } else {
        return moment(createdDate).format('DD/MM/YYYY')
    }
}

// export const validatePassword = (passValue) => {
//     const errors = [];
//     if (!regexList.minMaxLength.test(passValue)) {
//         errors.push(`•Min length 4 - Max length 20 \n`)
//     }
//     if (!regexList.noWhitespace.test(passValue)) {
//         errors.push(`•Shouldn't contain whitespaces \n`)
//     }
//     if (!regexList.containLowercase.test(passValue)) {
//         errors.push(`•Must contain 1 lowercase letter \n`)
//     }
//     if (!regexList.containUppercase.test(passValue)) {
//         errors.push(`•Must contain 1 uppercase letter \n`)
//     }
//     if (!regexList.containNumeric.test(passValue)) {
//         errors.push(`•Must contain 1 numeric character \n`)
//     }
//     if (!regexList.containSpecialCharacter.test(passValue)) {
//         errors.push(`•Must contain 1 special character \n`)
//     }
//     return errors.join('')
// }