import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "../../global/components/Header";
import Sidebar from "../../global/components/Sidebar";
import ContentWrapper from "../../global/components/ContentWrapper";
import BVCard from "../../global/components/BVCard";


const HomePage = () => {
	const [navHeight, setNavHeight] = useState(0);


	useEffect(() => {
		setTimeout(() => {
			const topNav = document.getElementById('top-navbar');
			if (topNav) {
				let height = topNav.offsetHeight;
				setNavHeight(height);
			}
		}, 100);
	}, [])

	return (
		<BVCard>
			<div>

			</div>
		</BVCard>
	)
}

export default HomePage