import api from "../api";
import axios from "axios";
import handleError from "./handle-error";
import { getAxiosConfig } from "./axios.config";

export const createProvisionLab = async () => {
    try {
        const { data } = await axios.get(api.createProvision());

        return data;
    } catch (err) {
        return handleError(err);
    }
}


export const terminateEC2 = async () => {
    try {
        const { data } = await axios.get(api.terminateEC2());
        return data;
    } catch (err) {
        return handleError(err);
    }
}


export const stopEC2 = async () => {
    try {
        const { data } = await axios.get(api.stopEC2());
        return data;
    } catch (err) {
        return handleError(err);
    }
}


