import React, { useEffect, useState } from "react";

const StarIcon = ({
    size = "24",
    fill = 'none',
    className
}) => {
    const [_class, setClass] = useState(className)

    useEffect(() => {
        let classStr = className || '';
        if (!classStr.includes('w-')) {
            classStr += ' w-5';
        }
        if (!classStr.includes('h-')) {
            classStr += ' h-5';
        }

        setClass(classStr);
    }, [className])
    return <svg viewBox={`0 0 24 24`} fill={fill} xmlns="http://www.w3.org/2000/svg" className={_class}>
        <path d="M11.5001 16.3309L15.3043 18.6318C16.001 19.0534 16.8535 18.4301 16.6701 17.6418L15.6618 13.3151L19.026 10.4001C19.6401 9.86845 19.3101 8.86011 18.5035 8.79595L14.076 8.42011L12.3435 4.33178C12.0318 3.58928 10.9685 3.58928 10.6568 4.33178L8.92429 8.41095L4.49679 8.78678C3.69013 8.85095 3.36013 9.85928 3.97429 10.3909L7.33846 13.3059L6.33013 17.6326C6.14679 18.4209 6.99929 19.0443 7.69596 18.6226L11.5001 16.3309Z" fill={fill} />
    </svg>

}
export default StarIcon
