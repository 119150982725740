import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getUserById } from '../../endpoints/users.service';
import BVCard from '../../global/components/BVCard';
import Courses from './components/Courses';
import Details from './components/Details';
import { getTrainerCourseSales } from '../../endpoints/transaction.service';
import { useSnackbar } from 'notistack';
import TrainerTransactions from './components/TrainerTransactions';

const tabs = [
    {
        label: 'Details',
        route: 'details'
    },
    {
        label: 'Courses',
        route: 'user-courses'
    },
    {
        label: 'Messages',
        route: 'messages'
    },
    {
        label: 'Transactions',
        route: 'user-transactions'
    }
]


const LabelContainer = ({ label, containerStyle }) => {
    return <div className={`flex rounded items-center justify-center bg-[#F5F5F5] border border-gray-300 h-10 w-64 ${containerStyle}`}>
        <span className='text-m text-sm font-bold text-gray-700'>{label}</span>
    </div>
}

const TabItem = ({ item, index, navigate }) => {
    const location = useLocation();
    const isSelected = location?.pathname?.includes(item?.route)
    return <>
        {
            index !== 0 ?
                <div className='h-5 w-px bg-gray-200' />
                :
                null
        }
        <button
            onClick={() => navigate(item?.route)}
            className={`rounded-md py-1 hover:bg-gray-100 text-blue-300 text-sm text-[gray] ${isSelected && 'underline'} px-5`}>{item?.label}</button>
    </>
}

const UserDetails = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();
    const [userDetails, setUserDetails] = useState();
    const [trainerTransactions, setTrainerTransactions] = useState({
        loading: false,
        initialLoaded: false,
        data: [],
        cursor: { next: null }
    });

    const fetchTrainerTransactions = () => {
        setTrainerTransactions({
            ...trainerTransactions,
            loading: true
        });
        getTrainerCourseSales(userDetails.id, null, trainerTransactions.cursor.next ?? null).then(( orderItems) => {
            setTrainerTransactions({
                loading: false,
                initialLoaded: true,
                data: [
                    ...(trainerTransactions.cursor?.next ? trainerTransactions.data : []),
                    ...orderItems.data
                ],
                cursor: { next: orderItems.cursor?.next }
            });
        })
        .catch(err => {
            enqueueSnackbar(err, { variant: "error" });
            setTrainerTransactions({
                ...trainerTransactions,
                loading: false
            });
        });
    }

    const fetchUserDetails = () => {
        getUserById(id).then((user) => {
            setUserDetails(user);
        });
    }

    useEffect(() => {
        if (userDetails && userDetails.id && !trainerTransactions.initialLoaded) {
            fetchTrainerTransactions();
        }
    }, [userDetails]);

    useEffect(() => {
        fetchUserDetails();
    }, [])

    return (
        <BVCard className="min-h-[30rem]">
            <div className='flex items-center w-full border-b h-10 p-10'>
                <img className='m-0' src={require('../../assets/icons/chevronLeft.png')} alt="me" width="15" height="15" />
                <span onClick={() => navigate(-1)} className='text-sky-600 text-sm mr-16 hover:cursor-pointer'>Back</span>
                <LabelContainer
                    label={userDetails?.name ?? ''}
                />
                <div className='flex ml-10 gap-1 items-center'>
                    {
                        tabs?.map((item, index) => {
                            return <TabItem
                                key={index}
                                item={item}
                                index={index}
                                navigate={navigate}
                            />
                        })
                    }
                </div>
            </div>

            {/* Detail Container */}
            <Routes>
                <Route index element={<Navigate to={'details'} />} />
                <Route path='details' element={<Details
                    userDetails={userDetails}
                />} />
                <Route path='user-courses' element={<Courses
                    userDetails={userDetails}
                />} />
                <Route path='messages' element={<Details
                    userDetails={userDetails}
                />} />
                <Route path='user-transactions' element={<TrainerTransactions trainerTransactions={trainerTransactions} />} />
            </Routes>
        </BVCard>
    )
}

export default UserDetails