import axios from "axios";
import api from "../api";
import handleError from "./handle-error";
import { getAxiosConfig } from "./axios.config";

export const getTrainerCourseSales = async (trainerUserId, courseId, cursor) => {
	try {
		let urlParams = new URLSearchParams();
		urlParams.append('trainerUserId', trainerUserId);
		if (courseId) {
			urlParams.append('courseId', courseId);
		}
		if (cursor) {
			urlParams.append('cursor', cursor);
		}

		const { data } = await axios.get(api.getTrainerCourseSales() + `/?${urlParams.toString()}`, getAxiosConfig());
		if (!data.success)
			throw new Error("Failed to fetch trainer course sales.");

		return data.orderItems;
	} catch (e) {
		return handleError(e);
	}
}