import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUsers } from '../../endpoints/users.service';
import ContentWrapper from '../../global/components/ContentWrapper';
import BVCard from '../../global/components/BVCard';

const UserPage = () => {
	const navigate = useNavigate();
	const [users, setUsers] = useState({
		data: [],
		cursor: { next: null }
	});
	const [activeTab, setActiveTab] = useState(1);
	const [loading, setLoading] = useState(false);

	const fetchUsers = async () => {
		const cursor = users.cursor?.next ?? undefined;

		setLoading(true);
		await getUsers(activeTab, cursor).then((data) => {
			setUsers({
				...users,
				data: [...users.data, ...data.users.data],
				cursor: data.users.cursor
			});
		}).finally(() => setLoading(false));
	}

	const onChangeTab = (value) => {
		if (value !== activeTab) {
			setUsers({
				data: [],
				cursor: { next: null }
			});

			setActiveTab(value);
		}
	}

	const onClickLoadMore = (e) => {
		e.preventDefault();

		if (users.cursor.next) {
			fetchUsers();
		}
	}

	function handleClick(id) {
		navigate(`detail/${id}`);
	}

	useEffect(() => {
		fetchUsers();
	}, [activeTab]);

	function handleClick(id) {
		navigate(`/users/${id}`);
	}

	return (
		<BVCard className="min-h-[30rem]">
			<div className='flex justify-center items-center w-full border-b h-10'>
				<span onClick={() => onChangeTab(1)} className='text-xs text-gray-400 hover:cursor-pointer hover:underline'>Students</span>
				<div className='border mx-10 h-full' />
				<span onClick={() => onChangeTab(2)} className='text-xs text-gray-400 hover:cursor-pointer hover:underline'>Trainers</span>
			</div>
			{/* Table container */}
			<div>
				<div className='flex w-full my-3 px-14'>
					<div className=' w-full border'>
						<div className='flex py-10 px-5'>
							<input className='border mr-5' />
							<input className='border mr-5' />
							<input className='border mr-5' />
						</div>
						<table className="w-full">
							<thead>
								<tr className='bg-gray-100'>
									<th className='text-gray-400 text-xs font-normal h-10'>Account Name</th>
									<th className='text-gray-400 text-xs font-normal h-10'>User Email</th>
									<th className='text-gray-400 text-xs font-normal h-10'>Users Name</th>
									<th className='text-gray-400 text-xs font-normal h-10'>Register Date / Location</th>
									<th className='text-gray-400 text-xs font-normal h-10'>Last Login / Location</th>
								</tr>
							</thead>
							<tbody>
								{
									users.data && users.data.length ? users?.data.map((user, index) => {
										return <tr className='hover:cursor-pointer' key={`${user.id}-${index}`}>
											<td onClick={() => handleClick(user?.id)} className={`border-l-0 text-black text-xs text-center border h-10 ${index === (users?.length - 1) ? 'border-b-0' : 'border-b'}`}>{user?.id}</td>
											<td onClick={() => handleClick(user?.id)} className={`text-black text-xs text-center border h-10 ${index === (users?.length - 1) ? 'border-b-0' : 'border-b'}`}>{user?.email}</td>
											<td onClick={() => handleClick(user?.id)} className={`text-black text-xs text-center border h-10 ${index === (users?.length - 1) ? 'border-b-0' : 'border-b'}`}>{user?.name}</td>
											<td onClick={() => handleClick(user?.id)} className={`text-black text-xs text-center border h-10 ${index === (users?.length - 1) ? 'border-b-0' : 'border-b'}`}>{user.registerDateLocation}</td>
											<td onClick={() => handleClick(user?.id)} className={`border-r-0 text-xs text-center border h-10 ${index === (users?.length - 1) ? 'border-b-0' : 'border-b'}`}>{user?.lastLoginDateLocation}</td>
										</tr>
									}) : null
								}
								{
									loading ? (
										<>
											{
												[1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => {
													return <tr className='hover:cursor-pointer w-full' key={`${item}-${index}`}>
														<td className={`border-l-0 text-black text-xs text-center border h-10 ${index == 7 ? 'border-b-0' : 'border-b'}`}>
															<div className="w-full h-full flex justify-center items-center">
																<div className="z-0 w-[80%] h-3 bg-gray-300 rounded animate-pulse"></div>
															</div>
														</td>
														<td className={`border-l-0 text-black text-xs text-center border h-10 ${index == 7 ? 'border-b-0' : 'border-b'}`}>
															<div className="w-full h-full flex justify-center items-center">
																<div className="z-0 w-[80%] h-3 bg-gray-300 rounded animate-pulse"></div>
															</div>
														</td>
														<td className={`border-l-0 text-black text-xs text-center border h-10 ${index == 7 ? 'border-b-0' : 'border-b'}`}>
															<div className="w-full h-full flex justify-center items-center">
																<div className="z-0 w-[80%] h-3 bg-gray-300 rounded animate-pulse"></div>
															</div>
														</td>
														<td className={`border-l-0 text-black text-xs text-center border h-10 ${index == 7 ? 'border-b-0' : 'border-b'}`}>
															<div className="w-full h-full flex justify-center items-center">
																<div className="z-0 w-[80%] h-3 bg-gray-300 rounded animate-pulse"></div>
															</div>
														</td>
														<td className={`border-r-0 text-black text-xs text-center border h-10 ${index == 7 ? 'border-b-0' : 'border-b'}`}>
															<div className="w-full h-full flex justify-center items-center">
																<div className="z-0 w-[80%] h-3 bg-gray-300 rounded animate-pulse"></div>
															</div>
														</td>
													</tr>
												})
											}
										</>
									) : null
								}
							</tbody>
						</table>
						{
								!loading && users.cursor.next ? (
									<div className="text-center">
										<a href="#" className="underline-none" role="button" onClick={onClickLoadMore}>Load More</a>
									</div>
								) : null
						}
					</div>
				</div>
			</div>
		</BVCard>
	)
}
export default UserPage;
