import { useEffect, useState } from "react";

const ThreeDotsVerticalIcon = ({ className, fill }) => {
    const [_class, setClass] = useState(className)

    useEffect(() => {
        let classStr = className || '';
        if (!classStr.includes('w-')) {
            classStr += ' w-5';
        }
        if (!classStr.includes('h-')) {
            classStr += ' h-5';
        }

        setClass(classStr);
    }, [className])

    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32" stroke-width="0" stroke={fill} class={_class}>
            <path d="M16.0007 21.333C16.7079 21.333 17.3862 21.614 17.8863 22.1141C18.3864 22.6142 18.6673 23.2924 18.6673 23.9997C18.6673 24.7069 18.3864 25.3852 17.8863 25.8853C17.3862 26.3854 16.7079 26.6663 16.0007 26.6663C15.2934 26.6663 14.6151 26.3854 14.115 25.8853C13.6149 25.3852 13.334 24.7069 13.334 23.9997C13.334 23.2924 13.6149 22.6142 14.115 22.1141C14.6151 21.614 15.2934 21.333 16.0007 21.333ZM16.0007 13.333C16.7079 13.333 17.3862 13.614 17.8863 14.1141C18.3864 14.6142 18.6673 15.2924 18.6673 15.9997C18.6673 16.7069 18.3864 17.3852 17.8863 17.8853C17.3862 18.3854 16.7079 18.6663 16.0007 18.6663C15.2934 18.6663 14.6151 18.3854 14.115 17.8853C13.6149 17.3852 13.334 16.7069 13.334 15.9997C13.334 15.2924 13.6149 14.6142 14.115 14.1141C14.6151 13.614 15.2934 13.333 16.0007 13.333ZM16.0007 5.33301C16.7079 5.33301 17.3862 5.61396 17.8863 6.11406C18.3864 6.61415 18.6673 7.29243 18.6673 7.99967C18.6673 8.70692 18.3864 9.3852 17.8863 9.88529C17.3862 10.3854 16.7079 10.6663 16.0007 10.6663C15.2934 10.6663 14.6151 10.3854 14.115 9.88529C13.6149 9.3852 13.334 8.70692 13.334 7.99967C13.334 7.29243 13.6149 6.61415 14.115 6.11406C14.6151 5.61396 15.2934 5.33301 16.0007 5.33301Z" fill={fill} />
        </svg>
    )
};

export default ThreeDotsVerticalIcon;