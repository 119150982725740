const TrainerTransactions = ({ trainerTransactions }) => {
	return (
		<div className='flex w-full my-3 px-10'>
            <div className=' w-full border'>
                <div className='flex py-10 px-5'>
                    <input className='border mr-5' />
                    <input className='border mr-5' />
                    <input className='border mr-5' />
                </div>
                <table className="w-full">
                    <thead>
                        <tr className='bg-gray-100'>
						<th className='text-gray-400 text-xs font-normal h-10'>Course</th>
                            <th className='text-gray-400 text-xs font-normal h-10'>Course ID</th>
                            <th className='text-gray-400 text-xs font-normal h-10'>Member</th>
                            <th className='text-gray-400 text-xs font-normal h-10'>Date</th>
                            <th className='text-gray-400 text-xs font-normal h-10'>Charged</th>
                            <th className='text-gray-400 text-xs font-normal h-10'>Transaction ID</th>
							<th className='text-gray-400 text-xs font-normal h-10'>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            trainerTransactions.loading ?
                                <>
                                    {
                                        [1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => {
                                            return <tr key={item} className='hover:cursor-pointer'>
                                                <td className={`border-l-0 text-black text-xs text-center border h-10 ${index == 7 ? 'border-b-0' : 'border-b'}`}>
                                                    <div className="w-full h-full flex justify-center items-center">
                                                        <div className="z-0 w-[80%] h-3 bg-gray-300 rounded animate-pulse"></div>
                                                    </div>
                                                </td>
                                                <td className={`border-l-0 text-black text-xs text-center border h-10 ${index == 7 ? 'border-b-0' : 'border-b'}`}>
                                                    <div className="w-full h-full flex justify-center items-center">
                                                        <div className="z-0 w-[80%] h-3 bg-gray-300 rounded animate-pulse"></div>
                                                    </div>
                                                </td>
                                                <td className={`border-l-0 text-black text-xs text-center border h-10 ${index == 7 ? 'border-b-0' : 'border-b'}`}>
                                                    <div className="w-full h-full flex justify-center items-center">
                                                        <div className="z-0 w-[80%] h-3 bg-gray-300 rounded animate-pulse"></div>
                                                    </div>
                                                </td>
                                                <td className={`border-l-0 text-black text-xs text-center border h-10 ${index == 7 ? 'border-b-0' : 'border-b'}`}>
                                                    <div className="w-full h-full flex justify-center items-center">
                                                        <div className="z-0 w-[80%] h-3 bg-gray-300 rounded animate-pulse"></div>
                                                    </div>
                                                </td>
                                                <td className={`border-r-0 text-black text-xs text-center border h-10 ${index == 7 ? 'border-b-0' : 'border-b'}`}>
                                                    <div className="w-full h-full flex justify-center items-center">
                                                        <div className="z-0 w-[80%] h-3 bg-gray-300 rounded animate-pulse"></div>
                                                    </div>
                                                </td>
                                                <td className={`border-r-0 text-black text-xs text-center border h-10 ${index == 7 ? 'border-b-0' : 'border-b'}`}>
                                                    <div className="w-full h-full flex justify-center items-center">
                                                        <div className="z-0 w-[80%] h-3 bg-gray-300 rounded animate-pulse"></div>
                                                    </div>
                                                </td>
                                                <td className={`border-r-0 text-black text-xs text-center border h-10 ${index == 7 ? 'border-b-0' : 'border-b'}`}>
                                                    <div className="w-full h-full flex justify-center items-center">
                                                        <div className="z-0 w-[80%] h-3 bg-gray-300 rounded animate-pulse"></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        })
                                    }
                                </>
                                :
                                <>
                                    {
                                        trainerTransactions.data?.map((orderItem, index) => {
                                            return <tr key={orderItem?.id + '-' + index} className='hover:cursor-pointer'>
                                                <td className={`border-l-0 text-black text-xs text-center border h-10 ${index === (trainerTransactions.data?.length - 1) ? 'border-b-0' : 'border-b'}`}>{orderItem?.topic?.topicTitle}</td>
												<td className={`border-l-0 text-black text-xs text-center border h-10 ${index === (trainerTransactions.data?.length - 1) ? 'border-b-0' : 'border-b'}`}>{orderItem?.topic?.id}</td>
                                                <td className={`text-black text-xs text-center border h-10 ${index === (trainerTransactions.data?.length - 1) ? 'border-b-0' : 'border-b'}`}>{ orderItem?.user?.name }</td>
                                                <td className={`text-black text-xs text-center border h-10 ${index === (trainerTransactions.data?.length - 1) ? 'border-b-0' : 'border-b'}`}>{ orderItem.orderDate }</td>
                                                <td className={`text-black text-xs text-center border h-10 ${index === (trainerTransactions.data?.length - 1) ? 'border-b-0' : 'border-b'}`}>{ orderItem.totalAmount }</td>
                                                <td className={`border-r-0 text-xs text-center font-semibold border h-10 ${index === (trainerTransactions.data?.length - 1) ? 'border-b-0' : 'border-b'}`}>{ orderItem.order.transactionId }</td>
												<td className={`border-r-0 text-xs text-center font-semibold border h-10 ${index === (trainerTransactions.data?.length - 1) ? 'border-b-0' : 'border-b'}`}>{ orderItem.order.orderStatus }</td>
                                            </tr>
                                        })
                                    }
                                </>
                        }

                    </tbody>
                </table>
            </div>
        </div>
	)
}

export default TrainerTransactions;