import React from "react";

const LanguageIcon = ({
    size = "24",
    fill = 'none'
}) => {
    return <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path d="M7.79685 14.3141L5.46851 12.0133L5.49601 11.9858C7.04899 10.2598 8.20925 8.21757 8.89685 5.99992H11.5827V4.16659H5.16601V2.33325H3.33268V4.16659H-3.08398V5.99992H7.15518C6.54101 7.75992 5.56935 9.43742 4.24935 10.9041C3.39685 9.95992 2.69102 8.92409 2.13185 7.83325H0.298515C0.967682 9.32742 1.88435 10.7391 3.03018 12.0133L-1.63565 16.6149L-0.333985 17.9166L4.24935 13.3333L7.10018 16.1841L7.79685 14.3141ZM12.9577 9.66659H11.1243L6.99935 20.6666H8.83268L9.85935 17.9166H14.2135L15.2493 20.6666H17.0827L12.9577 9.66659ZM10.556 16.0833L12.041 12.1141L13.526 16.0833H10.556Z" fill={fill} />
    </svg>

}
export default LanguageIcon
