import React from 'react';

const Header = () => {
	const onClickLogout = () => {
		localStorage.removeItem('token');
		window.location.href = '/';
		window.location.reload();
	}

    return (
		<div id="top-navbar" className="z-10 fixed bg-[#3984C7] w-full shadow-md" style={{ top: 0}}>
			<div className="flex w-full h-16 px-10 items-center justify-between self-center">
				{/* <h1 className='font-bold text-lg text-white'>CYBODIN</h1> */}
				<span className="font-light text-white text-lg font-montserrat tracking-[0.4rem]">CYBODIN</span>
				<span className='text-sm text-white'>Admin Panel</span>
				<div className="relative h-full py-2" id="nav-profile-container">
					<div className='flex items-center'>
						<span className='font-bold text-xs mr-2 text-white'>Harry Kane</span>
						<img src={require('../../assets/images/avatar.png')} alt="me" width="50" height="50" />
					</div>
					<div id="nav-profile-drop" className="absolute table right-0 top-16 bg-white shadow-md border min-w-[10rem]">
						<a href="#" className="block px-8 py-2 hover:bg-slate-100 w-full">Profile</a>
						<div className="block w-full bg-gray-400 h-px"></div>
						<a role='button' className="block px-8 py-2 hover:bg-slate-100 w-full"
							onClick={onClickLogout}
						>Logout</a>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Header