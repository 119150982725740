import React from "react";

const ArrowBackIcon = ({
  size = "24",
  fill = 'none'
}) => {
  return <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M27.7075 16.5417H11.4179L18.5346 9.42505C19.1033 8.8563 19.1033 7.92296 18.5346 7.35421C18.3996 7.21902 18.2394 7.11177 18.063 7.03858C17.8865 6.9654 17.6974 6.92773 17.5064 6.92773C17.3154 6.92773 17.1263 6.9654 16.9499 7.03858C16.7735 7.11177 16.6132 7.21902 16.4783 7.35421L6.86789 16.9646C6.73269 17.0995 6.62544 17.2598 6.55226 17.4362C6.47908 17.6126 6.44141 17.8018 6.44141 17.9928C6.44141 18.1838 6.47908 18.3729 6.55226 18.5493C6.62544 18.7257 6.73269 18.886 6.86789 19.0209L16.4783 28.6313C16.6133 28.7663 16.7736 28.8734 16.95 28.9465C17.1264 29.0196 17.3155 29.0572 17.5064 29.0572C17.6974 29.0572 17.8864 29.0196 18.0628 28.9465C18.2393 28.8734 18.3995 28.7663 18.5346 28.6313C18.6696 28.4963 18.7767 28.336 18.8497 28.1596C18.9228 27.9832 18.9604 27.7941 18.9604 27.6032C18.9604 27.4122 18.9228 27.2232 18.8497 27.0468C18.7767 26.8703 18.6696 26.7101 18.5346 26.575L11.4179 19.4584H27.7075C28.5096 19.4584 29.1658 18.8021 29.1658 18C29.1658 17.198 28.5096 16.5417 27.7075 16.5417Z" fill={fill} />
  </svg>

}
export default ArrowBackIcon
