import React, { useEffect, useState } from "react";

const PublishIcon = ({
    size = "24",
    fill = 'none',
    className
}) => {
    const [_class, setClass] = useState(className)

    useEffect(() => {
        let classStr = className || '';
        if (!classStr.includes('w-')) {
            classStr += ' w-5';
        }
        if (!classStr.includes('h-')) {
            classStr += ' h-5';
        }

        setClass(classStr);
    }, [className])
    return <svg viewBox={`0 0 24 24`} fill={fill} xmlns="http://www.w3.org/2000/svg" className={_class}>
        <path d="M10.5 4.06641L5.77734 8.77734L4.72266 7.72266L11.25 1.19531L17.7773 7.72266L16.7227 8.77734L12 4.06641V19.5H10.5V4.06641ZM19.5 19.5H21V24H1.5V19.5H3V22.5H19.5V19.5Z" fill={fill} />
    </svg>

}
export default PublishIcon
