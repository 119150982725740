import React from "react";

const UnpublishIcon = ({
    size = "24",
    fill = 'none'
}) => {
    return <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill={fill} xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_117_260)">
            <path d="M6.125 2.5415L3.17334 5.48584L2.51416 4.82666L6.59375 0.74707L10.6733 4.82666L10.0142 5.48584L7.0625 2.5415V12.1875H6.125V2.5415ZM15.5 11.7188C15.5 12.1729 15.4146 12.5977 15.2437 12.9932C15.0728 13.3887 14.8384 13.7378 14.5405 14.0405C14.2427 14.3433 13.896 14.5776 13.5005 14.7437C13.105 14.9097 12.6777 14.9951 12.2188 15C11.7646 15 11.3398 14.9146 10.9443 14.7437C10.5488 14.5728 10.1997 14.3384 9.89697 14.0405C9.59424 13.7427 9.35986 13.396 9.19385 13.0005C9.02783 12.605 8.94238 12.1777 8.9375 11.7188C8.9375 11.2646 9.02295 10.8398 9.19385 10.4443C9.36475 10.0488 9.59912 9.69971 9.89697 9.39697C10.1948 9.09424 10.5415 8.85986 10.937 8.69385C11.3325 8.52783 11.7598 8.44238 12.2188 8.4375C12.6729 8.4375 13.0977 8.52295 13.4932 8.69385C13.8887 8.86475 14.2378 9.09912 14.5405 9.39697C14.8433 9.69482 15.0776 10.0415 15.2437 10.437C15.4097 10.8325 15.4951 11.2598 15.5 11.7188ZM13.5078 13.6743L10.2632 10.4297C10.1362 10.6201 10.041 10.8252 9.97754 11.0449C9.91406 11.2646 9.87988 11.4893 9.875 11.7188C9.875 12.0459 9.93604 12.3511 10.0581 12.6343C10.1802 12.9175 10.3462 13.1641 10.5562 13.374C10.7661 13.584 11.0151 13.7524 11.3032 13.8794C11.5913 14.0063 11.8965 14.0674 12.2188 14.0625C12.4482 14.0625 12.6729 14.0308 12.8926 13.9673C13.1123 13.9038 13.3174 13.8062 13.5078 13.6743ZM14.5625 11.7188C14.5625 11.3965 14.5015 11.0938 14.3794 10.8105C14.2573 10.5273 14.0889 10.2808 13.874 10.0708C13.6592 9.86084 13.4102 9.69238 13.127 9.56543C12.8438 9.43848 12.541 9.375 12.2188 9.375C11.9893 9.375 11.7646 9.40674 11.5449 9.47021C11.3252 9.53369 11.1201 9.63135 10.9297 9.76318L14.1743 13.0078C14.3013 12.8174 14.3965 12.6123 14.46 12.3926C14.5234 12.1729 14.5576 11.9482 14.5625 11.7188ZM8.44678 14.0625C8.5542 14.2432 8.67139 14.4116 8.79834 14.5679C8.92529 14.7241 9.06934 14.8682 9.23047 15H0.5V12.1875H1.4375V14.0625H8.44678Z" fill={fill} />
        </g>
        <defs>
            <clipPath id="clip0_117_260">
                <rect width="15" height="15" fill="white" transform="translate(0.5)" />
            </clipPath>
        </defs>
    </svg>

}
export default UnpublishIcon
