import axios from "axios";
import { useEffect, useState } from "react";
import api from "../../api";
import { getAxiosConfig } from "../../endpoints/axios.config";
import { NewsFeedCard } from "./NewsFeed";

const NewsSource = () => {
  const [refresh, setRefresh] = useState(false);
  const [sources, setSources] = useState([]);
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState("");
  const [feedsDatasPreview, setFeedsDatasPreview] = useState([]);
  const [feedTitle, setFeedTitle] = useState("");

  useEffect(() => {
    axios.get(api.getFeedSource(true), getAxiosConfig()).then((res) => {
      setSources(res?.data);
    });
  }, [refresh]);

  const handlePublishSource = (id) =>
    axios
      .delete(api.deleteSource(id), getAxiosConfig())
      .then((_res) => setRefresh(!refresh));

  const handleRemoveSource = (id) =>
    axios
      .delete(api.deleteSource(id, true), getAxiosConfig())
      .then((_res) => setRefresh(!refresh));

  const assignDeveloper = () => {
    if (!feedTitle) {
      alert("Please enter a feed title");
      return;
    }
    if (!link) {
      alert("Please enter a link");
      return;
    }
    axios
      .post(
        api.assignFeedToDeveloper,
        {
          title: feedTitle,
          link,
        },
        getAxiosConfig()
      )
      .then((_res) => {
        alert("successfully assigned to developer");
        setFeedTitle("");
        setLink("");
        setOpen(false);
        setRefresh(!refresh);
      });
  };

  const getRssFeedsFromSource = () => {
    if (!link) {
      alert("Please enter a link");
      return;
    }
    fetch(api.getFeedXmlByLink, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAxiosConfig().headers.Authorization,
      },
      body: JSON.stringify({ link: link.trim() }),
    })
      .then((res) => res.text())
      .then((data) => {
        const parser = new DOMParser();
        const xml = parser.parseFromString(data, "text/xml");
        const items = xml.querySelectorAll("item");
        const feedsDatas = [];
        items.forEach((el) => {
          const link = el.querySelector("link")?.innerHTML;
          const segments = link.split("/");
          const lastSegment = segments.length - 1;
          const title = el.querySelector("title")?.innerHTML;
          const pubDate = el.querySelector("pubDate")?.innerHTML;
          const creator = el.querySelector("creator")?.innerHTML;
          const description = el.querySelector("description")?.innerHTML;
          const itemId = segments[lastSegment];

          feedsDatas.push({
            lastSegment,
            title: title?.replaceAll("<![CDATA[", "").replaceAll("]]>", ""),
            link: link?.replaceAll("<![CDATA[", "").replaceAll("]]>", ""),
            description: description
              ?.replaceAll("<![CDATA[", "")
              .replaceAll("]]>", ""),
            pubDate: pubDate?.replaceAll("<![CDATA[", "").replaceAll("]]>", ""),
            creator: creator?.replaceAll("<![CDATA[", "").replaceAll("]]>", ""),
            itemId,
          });
        });
        console.log("feedsDatas", feedsDatas);
        setFeedsDatasPreview(feedsDatas);
      })
      .catch((err) => {
        alert("Cannot preview, developer work still needed.");
        console.log(err);
      });
  };

  return (
    <>
      {open && (
        <div
          style={{ zIndex: 9999 }}
          className="text-black fixed p-4 inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50"
        >
          <div className="bg-white p-6 w-auto h-auto max-h-screen overflow-scroll flex items-center flex-col justify-centers rounded-xl shadow-lg relative">
            <div className="absolute top-0 right-0">
              <button
                onClick={() => setOpen(false)}
                className="text-black text-2xl rounded mr-2 mt-1"
              >
                &times;
              </button>
            </div>
            <div className="w-full pb-2 text-center text-lg">
              Add Feed Source
            </div>
            <div className=" w-72 h-48 bg-white flex">
              <form
                className="w-full flex flex-col mt-4"
                onSubmit={(e) => e.preventDefault()}
              >
                <input
                  type="text"
                  name="title"
                  placeholder="Feed Name"
                  className="p-2 border mb-2"
                  onChange={(e) => setFeedTitle(e.target.value)}
                />
                <input
                  type="text"
                  name="link"
                  onChange={(e) => setLink(e.target.value)}
                  placeholder="Feed Link"
                  className="p-2 border mb-2"
                />
                <button
                  className="button mt-2 bg-green-600 py-2 text-white"
                  onClick={() => getRssFeedsFromSource()}
                >
                  Preview
                </button>
                <button
                  className="button mt-2 bg-green-600 py-2 text-white"
                  onClick={() => assignDeveloper()}
                >
                  Assign to Developer
                </button>
              </form>
            </div>
            <br />
            {feedsDatasPreview.length > 0 && (
              <div className="mt-12 flex flex-col gap-5">
                {feedsDatasPreview.length && <h1>Preview Feeds</h1>}
                {feedsDatasPreview?.map((item, itemIndex) => {
                  return (
                    <div className="flex w-full">
                      <div className="w-full">
                        <NewsFeedCard data={item} />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}

      <div className="w-full flex mt-10 flex-col">
        <button
          className="button bg-green-600 px-3 py-2 w-28 self-end mb-2 text-white"
          onClick={() => setOpen(true)}
        >
          Create
        </button>
        <table className="w-full  border table">
          <tr>
            <th className="p-2 border">Feed Name</th>
            <th className="p-2 border"></th>
            <th className="p-2 border">Last feed pull</th>
            <th className="p-2 border">24 hr total</th>
            <th className="p-2 border">Status</th>
            <th className="p-2 border"></th>
          </tr>
          {sources.map((source) => {
            return (
              <tr>
                <td className="p-2 border">{source.title}</td>
                <td className="p-2 border">{source.link}</td>
                <td className="p-2 border">
                  {new Date(source.lastPulledAt).toUTCString()}
                </td>
                <td className="p-2 border">{source.twentyFourHrsFeedsCount}</td>
                <td className="p-2 border">
                  {source.status === "assigned" && (
                    <span>Developing in progress</span>
                  )}
                  {source.status === "ready" && <span>Ready to publish</span>}
                </td>
                <td className="p-2 border text-center">
                  <button
                    onClick={() => handlePublishSource(source.id)}
                    className="text-sm p-2"
                  >
                    {source.deletedAt ? "Publish" : "Unpublish"}
                  </button>
                  <button
                    onClick={() => handleRemoveSource(source.id)}
                    className="text-sm p-2"
                  >
                    Remove
                  </button>
                </td>
              </tr>
            );
          })}
        </table>
      </div>
    </>
  );
};

export default NewsSource;
