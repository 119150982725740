import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import {
  createProvisionLab,
  stopEC2,
  terminateEC2,
} from "../../endpoints/aws.services";
import BVCard from "../../global/components/BVCard";
import api from "../../api";
import { getAxiosConfig } from "../../endpoints/axios.config";
import axios from "axios";
import NewsFeed from "./NewsFeed";
import NewsSource from "./NewsSource";

const Feeds = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [photos, setPhotos] = useState({});
  const [feeds, setFeeds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [oldfeeds, setOldFeeds] = useState([]);
  const [checked, setChecked] = useState([]);
  const allowMultiple = useRef();
  const [tab, setTab] = useState('feed');

  useEffect(() => {
    const fetchCyberSecurityRss = () => {
      fetch(api.getRssFeeds("cybersecurity"), getAxiosConfig())
        .then((response) => response.text())
        .then((str) => new window.DOMParser().parseFromString(str, "text/xml"))
        .then((data) => {
          const lastBuildDate = new Date(
            data.querySelector("lastBuildDate").innerHTML
          ).toISOString();
          const items = data.querySelectorAll("item");
          const feedsDatas = [];
          const photosObj = {};
          items.forEach((el) => {
            const link = el.querySelector("link").innerHTML;
            const segments = link.split("/");
            const lastSegment = segments.length - 2;
            const title = el.querySelector("title").innerHTML;
            const pubDate = el.querySelector("pubDate").innerHTML;
            const creator = el.querySelector("creator").innerHTML;
            const description = el.querySelector("description").innerHTML;
            const itemId = segments[lastSegment];
            fetch(
              api.getRssFeedDetail(itemId, title, lastBuildDate),
              getAxiosConfig()
            )
              .then((response) => response.text())
              .then((str) =>
                new window.DOMParser().parseFromString(str, "text/xml")
              )
              .then((data) => {
                const items = data.querySelectorAll("item");
                items.forEach((href) => {
                  const src = href.innerHTML
                    .replace("<![CDATA[", "")
                    .replace("]]>", "");
                  setPhotos({
                    ...photosObj,
                    [itemId]: src,
                  });
                  photosObj[itemId] = src;
                });
              })
              .catch((err) => {
                photosObj[itemId] = null;
                setPhotos({
                  ...photosObj,
                  [itemId]: null,
                });
                console.log(err);
              });
            feedsDatas.push({
              lastSegment,
              title,
              link,
              description,
              pubDate,
              creator,
              itemId,
            });
          });
          setFeeds(feedsDatas);
        });
    };

    const fetchOldFeedsCyberSecurityRss = () => {
      axios
        .get(api.getOldFeeds("cybersecurity"), getAxiosConfig())
        .then((res) => {
          const datas = res.data;
          setOldFeeds(datas);
          if (datas.length > 0) {
            setTimeout(() => {
              showOldFeeds(datas, datas[0].lastBuildDate);
            }, 500);
          }
        });
    };

    // fetchCyberSecurityRss();
    // fetchOldFeedsCyberSecurityRss();
  }, []);

  useEffect(() => {
    if (feeds.length === 10 && Object.keys(photos).length === 10) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [feeds, photos]);

  const changeOldFeeds = async (e) => {
    const lastBuildDate = e.target.value;
    if (!lastBuildDate) return;
    showOldFeeds(oldfeeds, lastBuildDate);
  };

  const showOldFeeds = async (oldfeedsPara, lastBuildDate) => {
    const selectedDateFeed = oldfeedsPara.find(
      (feed) => feed.lastBuildDate === lastBuildDate
    );
    const data = new window.DOMParser().parseFromString(
      selectedDateFeed.xml,
      "text/xml"
    );
    const items = data.querySelectorAll("item");
    const feedsDatas = [];
    const photosObj = {};
    const datas = await axios.get(
      api.getOldFeedsImgs(lastBuildDate),
      getAxiosConfig()
    );
    datas.data.map((data) => {
      photosObj[data.urlSegment] = data.href;
    });

    items.forEach((el) => {
      const link = el.querySelector("link").innerHTML;
      const segments = link.split("/");
      const lastSegment = segments.length - 2;
      const title = el.querySelector("title").innerHTML;
      const pubDate = el.querySelector("pubDate").innerHTML;
      const creator = el.querySelector("creator").innerHTML;
      const description = el.querySelector("description").innerHTML;
      const itemId = segments[lastSegment];
      const categories = el.querySelectorAll("category");
      const tags = [];
      categories.forEach((tag) => {
        if (!tags.includes(tag.textContent.toLocaleLowerCase())) {
          tags.push(tag.textContent.toLocaleLowerCase());
        }
      });
      feedsDatas.push({
        lastSegment,
        title,
        link,
        description,
        pubDate,
        creator,
        itemId,
        lastBuildDate,
        tags,
      });
    });
    setFeeds(feedsDatas);
    setPhotos(photosObj);
  };

  const handleDelete = (id) => {
    const feedWithoutDeleteOne = feeds.filter((feed) => feed.itemId !== id);
    setFeeds(feedWithoutDeleteOne);
  };

  const handleCheck = (itemId) => {
    const checkedWithoutClickedOne = checked.filter(
      (check) => check !== itemId
    );
    if (checked.includes(itemId)) {
      setChecked(checkedWithoutClickedOne);
    } else {
      setChecked((prev) => [...prev, itemId]);
    }
  };

  const handleAllowMultipleFeed = () => {
    const feedWithChecked = feeds.filter((feed) =>
      checked.includes(feed.itemId)
    );
    const feedsToInsert = feedWithChecked.map(
      ({
        title,
        link,
        description,
        pubDate,
        creator,
        itemId,
        lastBuildDate,
        tags,
      }) => ({
        title,
        link,
        description,
        pubDate: new Date(pubDate),
        creator,
        itemId,
        lastBuildDate: new Date(lastBuildDate),
        imghref: photos[itemId],
        tags,
      })
    );

    axios
      .post(api.saveFeeds, { data: feedsToInsert }, getAxiosConfig())
      .then((_res) => {
        const feedWithoutChecked = feeds.filter(
          (feed) => !checked.includes(feed.itemId)
        );
        setFeeds(feedWithoutChecked);
        setChecked([]);
      });
  };

  const handleAllowSingleFeed = (itemId) => {
    handleCheck(itemId);
    setTimeout(() => {
      allowMultiple.current.click();
    }, 1000);
  };

  return (
    <BVCard>
      <div className="border-b h-10 w-full" />
      <div className="p-10">
        {/* <div className="flex flex-col gap-2 p-10 border rounded-md">
          <h1 className="font-semibold text-lg mb-4">Rss Feeds</h1>
          <select onChange={changeOldFeeds} className="border-gray-600 border">
            <option value="">Select Last Updated Date</option>
            {oldfeeds.map((feed) => {
              return <option>{feed.lastBuildDate}</option>;
            })}
          </select>
          <div className="w-full flex mt-4 items-center">
            <input
              onClick={(e) =>
                e.target.checked
                  ? setChecked(feeds.map((feed) => feed.itemId))
                  : setChecked([])
              }
              type="checkbox"
              value=""
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500 mr-3"
            />
            Check All
          </div>
          {checked.length > 0 && (
            <button
              ref={allowMultiple}
              onClick={() => handleAllowMultipleFeed()}
              class="bg-green-600 text-sm px-3 py-2.5 w-56 h-full font-semibold text-white rounded-sm"
            >
              Allow {checked.length} feeds
            </button>
          )}
          {!loading ? (
            <>
              {feeds.map((feed) => {
                return (
                  <div className="w-full flex py-5 items-start">
                    <div className="w-6 mt-2">
                      <input
                        type="checkbox"
                        checked={checked.includes(feed.itemId)}
                        onChange={(e) => handleCheck(feed.itemId)}
                        value=""
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                    </div>
                    <div
                      className={`w-64 h-32 mt-2 bg-gray-200 ${
                        !photos[feed.itemId] && "animate-pulse"
                      }`}
                    >
                      <a href={feed.link}>
                        <img
                          src={photos[feed.itemId]}
                          alt={feed.title}
                          className="w-full h-full"
                        />
                      </a>
                    </div>
                    <div className="pl-8 w-10/12 flex flex-col text-gray-500 ">
                      <div className="w-auto min-h-9 text-lg pb-1">
                        <a
                          href={feed.link}
                          dangerouslySetInnerHTML={{ __html: feed.title }}
                        />
                      </div>
                      <div className="w-auto h-6 text-sm font-semibold">
                        {feed.creator.substring(9, feed.creator.length - 3)} -{" "}
                        {feed.pubDate.substring(5, 16)}
                      </div>
                      <div
                        className="mt-4 w-11/12 h-auto"
                        dangerouslySetInnerHTML={{
                          __html: feed.description.substring(0, 300) + "...",
                        }}
                      />
                      <div className="w-full flex buttons">
                        <div className="mt-4 w-28 h-10">
                          <button
                            class="bg-green-600 text-sm px-3 py-1.5 w-full h-full font-semibold text-white rounded-sm"
                            onClick={() => handleAllowSingleFeed(feed.itemId)}
                          >
                            Allow
                          </button>
                        </div>
                        <div className="mt-4 ml-2 w-28 h-10">
                          <button
                            onClick={() => handleDelete(feed.itemId)}
                            class="bg-red-500 text-sm px-3 py-1.5 w-full h-full font-semibold text-white rounded-sm"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {[1, 2, 3, 4, 5].map((i) => {
                return (
                  <div className="w-full flex py-4">
                    <div className="w-64 bg-gray-200 animate-pulse h-32"></div>
                    <div className="pl-8 w-full flex flex-col">
                      <div className="w-1/3 h-6 bg-gray-200 animate-pulse "></div>
                      <div className="mt-4 w-3/4 h-4 bg-gray-200 animate-pulse "></div>
                      <div className="mt-4 w-11/12 h-4 bg-gray-200 animate-pulse "></div>
                      <div className="w-full flex buttons">
                        <div className="mt-4 w-28 bg-gray-200 animate-pulse h-10"></div>
                        <div className="mt-4 ml-2 w-28 bg-gray-200 animate-pulse h-10"></div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div> */}

        <div class="flex items-center bg-white flex-1 rounded-lg shadow-nShadow h-14 justify-around w-10/12">
          <button class="flex flex-col justify-center items-center h-full relative" onClick={() => setTab('feed')}>
            <span class={`font-montserrat ${tab === 'feed' && 'text-light-black'} text-sm font-semibold`}>News Feed</span>
            {tab === 'feed' && <div class="h-1 rounded-t-lg bg-black w-[90%] absolute bottom-0"></div>}
          </button>
          <button class="flex flex-col justify-center items-center h-full relative" onClick={() => setTab('source')}>
            <span class={`font-montserrat ${tab === 'feed' && 'text-light-black'} text-sm font-semibold`}>News Feed Source</span>
            {tab === 'source' && <div class="h-1 rounded-t-lg bg-black w-[90%] absolute bottom-0"></div>}
          </button>
        </div>
        {tab === 'feed' && <NewsFeed />}
        {tab === 'source' && <NewsSource />}
      </div>
    </BVCard >
  );
};

export default Feeds;
