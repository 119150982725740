import React,{useState,useEffect} from 'react'
import BVCard from '../components/BVCard'

const Page404 = ()=>{
    return <BVCard>
        <div className='h-full w-full'>
        <span className='font-bold text-xl'>Page not found.</span>
        </div>
    </BVCard>
}
export default Page404