import React from "react";

const LinkedInIcon = ({
    size = "24",
    fill = 'none'
}) => {
    return <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path d="M30.875 4.875C31.737 4.875 32.5636 5.21741 33.1731 5.8269C33.7826 6.4364 34.125 7.26305 34.125 8.125V30.875C34.125 31.737 33.7826 32.5636 33.1731 33.1731C32.5636 33.7826 31.737 34.125 30.875 34.125H8.125C7.26305 34.125 6.4364 33.7826 5.8269 33.1731C5.21741 32.5636 4.875 31.737 4.875 30.875V8.125C4.875 7.26305 5.21741 6.4364 5.8269 5.8269C6.4364 5.21741 7.26305 4.875 8.125 4.875H30.875ZM30.0625 30.0625V21.45C30.0625 20.045 29.5044 18.6976 28.5109 17.7041C27.5174 16.7106 26.17 16.1525 24.765 16.1525C23.3837 16.1525 21.775 16.9975 20.995 18.265V16.4613H16.4613V30.0625H20.995V22.0513C20.995 20.8 22.0025 19.7762 23.2537 19.7762C23.8571 19.7762 24.4358 20.0159 24.8624 20.4426C25.2891 20.8692 25.5287 21.4479 25.5287 22.0513V30.0625H30.0625ZM11.18 13.91C11.904 13.91 12.5984 13.6224 13.1104 13.1104C13.6224 12.5984 13.91 11.904 13.91 11.18C13.91 9.66875 12.6913 8.43375 11.18 8.43375C10.4516 8.43375 9.75313 8.72309 9.23811 9.23811C8.72309 9.75313 8.43375 10.4516 8.43375 11.18C8.43375 12.6913 9.66875 13.91 11.18 13.91ZM13.4388 30.0625V16.4613H8.9375V30.0625H13.4388Z" fill={fill} />
    </svg>

}
export default LinkedInIcon
