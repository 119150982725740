import React, { useEffect, useState } from "react";

const AddUserIcon = ({
    size = "24",
    fill = 'none',
    className
}) => {
    const [_class, setClass] = useState(className)

    useEffect(() => {
        let classStr = className || '';
        if (!classStr.includes('w-')) {
            classStr += ' w-5';
        }
        if (!classStr.includes('h-')) {
            classStr += ' h-5';
        }

        setClass(classStr);
    }, [className])
    return <svg viewBox={`0 0 24 24`} fill={fill} xmlns="http://www.w3.org/2000/svg" className={_class}>
        <path d="M14.2493 13.3334C11.8018 13.3334 6.91602 14.5526 6.91602 17.0001V18.8334H21.5827V17.0001C21.5827 14.5526 16.6968 13.3334 14.2493 13.3334ZM5.99935 9.66675V6.91675H4.16602V9.66675H1.41602V11.5001H4.16602V14.2501H5.99935V11.5001H8.74935V9.66675M14.2493 11.5001C15.2218 11.5001 16.1544 11.1138 16.8421 10.4261C17.5297 9.73851 17.916 8.80588 17.916 7.83342C17.916 6.86095 17.5297 5.92832 16.8421 5.24069C16.1544 4.55306 15.2218 4.16675 14.2493 4.16675C13.2769 4.16675 12.3443 4.55306 11.6566 5.24069C10.969 5.92832 10.5827 6.86095 10.5827 7.83342C10.5827 8.80588 10.969 9.73851 11.6566 10.4261C12.3443 11.1138 13.2769 11.5001 14.2493 11.5001Z" fill={fill} />
    </svg>

}
export default AddUserIcon
