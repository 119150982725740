import api from "../api";
import axios from "axios";
import handleError from "./handle-error";

export const login = async (email, password) => {
    try {
        const { data } = await axios.post(api.login, {
            email,
            password
        });
        return data;
    } catch (err) {
        return handleError(err);
    }
}