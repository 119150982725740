import React from "react";

const CommentIcon = ({
    size = "24",
    fill = 'none'
}) => {
    return <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill={'none'} xmlns="http://www.w3.org/2000/svg">
        <path d="M7.5 12.5H7.106C6.71094 12.4999 6.32469 12.6168 5.996 12.836L3.5 14.5V5.5C3.5 4.96957 3.71071 4.46086 4.08579 4.08579C4.46086 3.71071 4.96957 3.5 5.5 3.5H12.5C13.0304 3.5 13.5391 3.71071 13.9142 4.08579C14.2893 4.46086 14.5 4.96957 14.5 5.5V7.5M12.5 10.5H19.5C20.0304 10.5 20.5391 10.7107 20.9142 11.0858C21.2893 11.4609 21.5 11.9696 21.5 12.5V21.5L19.004 19.836C18.6753 19.6168 18.2891 19.4999 17.894 19.5H12.5C11.9696 19.5 11.4609 19.2893 11.0858 18.9142C10.7107 18.5391 10.5 18.0304 10.5 17.5V12.5C10.5 11.9696 10.7107 11.4609 11.0858 11.0858C11.4609 10.7107 11.9696 10.5 12.5 10.5Z" stroke={fill} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

}
export default CommentIcon
